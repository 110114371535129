import { Injectable } from '@angular/core';
import { CorrectionsMessagesValue } from '@shared/data-values/corrections/messages.data';
import { ErrorModel } from '@shared/models/shared/error.model';
import { Envelope, MoreInfo } from '@xpo-ltl/sdk-common';

@Injectable({
  providedIn: 'root',
})
export class HandleErrorApiService {
  constructor() { }

  handleError<T>(errorEnvelope: Envelope<T>, generalErrorMessage: string = CorrectionsMessagesValue.apis.serviceError): ErrorModel[] {
    if (errorEnvelope?.error?.moreInfo?.length > 0) {
      return errorEnvelope.error.moreInfo.map((infoError: MoreInfo) => {
        const error = new ErrorModel();
        error.message = infoError.message;
        error.location = infoError.location;
        error.code = errorEnvelope?.code
        error.errorCode = errorEnvelope?.error?.errorCode;
        error.generalMessage = errorEnvelope?.error?.message;

        return error;
      });
    }

    const error = new ErrorModel();
    error.message = generalErrorMessage;
    error.code = errorEnvelope?.code
    error.errorCode = errorEnvelope?.error?.errorCode;
    error.generalMessage = errorEnvelope?.error?.message;
    return [error];
  }
}

import { Injectable } from '@angular/core';
import { UserRolesService } from '../user-roles.service';

@Injectable({
  providedIn: 'root'
})
export class CorrectionsGeneralRolesService {

  constructor(
    private userRolesService: UserRolesService
  ) { }

  displayAuditorFunctionTab(): boolean {
    return this.userRolesService.checkIfAnyRoleMatch(
      [
        this.userRolesService.ramRolesKeyEnum.auditorRamRole,
        this.userRolesService.ramRolesKeyEnum.inspectorRamRole,
        this.userRolesService.ramRolesKeyEnum.managerRamRole,
        this.userRolesService.ramRolesKeyEnum.supervisorRamRole,
        this.userRolesService.ramRolesKeyEnum.swoAuditorRamRole,
        this.userRolesService.ramRolesKeyEnum.vicePresidentRamRole,
      ],
      this.userRolesService.loggedUserRoles
    );
  }
}

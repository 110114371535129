import { Injectable } from '@angular/core';
import { UserHandler } from '@app-store/user/handler/user.handler';
import { ConfigManagerProperties } from '@shared/enums/common-data/config-manager-properties.enum';
import { RamRolesEnum, RamRolesKeyEnum, RamRolesTestEnum } from '@shared/enums/common-data/ram-roles.enum';
import { UserRamRolesModel, UserRolesModel } from '@shared/models/shared/user.model';
import { RegexRules } from '@shared/utils/regex/regex.utils';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserRolesService {

  userRamRoles: UserRamRolesModel = {};
  ramRolesKeyEnum = RamRolesKeyEnum;
  loggedUserRoles: UserRolesModel | undefined;

  private isProd = this.configManagerService.getSetting<boolean>(ConfigManagerProperties.production);
  private ramRolesEnum = RamRolesEnum;
  private ramRolesTestEnum = RamRolesTestEnum;

  constructor(
    private configManagerService: ConfigManagerService,
    private userHandler: UserHandler
  ) {
    this.setRamRolesToCheck();
    this.setRamRoles();
  }

  getRamRolesToCheck(keyRamRoles: RamRolesKeyEnum[]): string[] {
    let rolesToCheck: string[] = [];
    keyRamRoles.forEach(role => {
      if (this.userRamRoles[role]) {
        rolesToCheck = [...rolesToCheck, ...this.userRamRoles[role]];
      }
    });

    return rolesToCheck;
  }

  doesAnyRoleMatch(ramRolesToCheck: string[], loggedUserRoles: UserRolesModel | undefined): boolean {
    return loggedUserRoles ? ramRolesToCheck.some(ramRoles => loggedUserRoles[ramRoles]) : false;
  }

  checkIfAnyRoleMatch(keyRamRoles: RamRolesKeyEnum[], loggedUserRoles: UserRolesModel | undefined): boolean {
    const ramRolesToCheck = this.getRamRolesToCheck(keyRamRoles);
    return this.doesAnyRoleMatch(ramRolesToCheck, loggedUserRoles);
  }

  private setRamRoles(): void {
    this.userRamRoles = {
      [this.ramRolesKeyEnum.auditorRamRole]: this.isProd ? [this.ramRolesEnum.auditorRamRole] : [this.ramRolesEnum.auditorRamRole, this.ramRolesTestEnum.auditorRamRole],
      [this.ramRolesKeyEnum.inspectorRamRole]: this.isProd ? [this.ramRolesEnum.inspectorRamRole] : [this.ramRolesEnum.inspectorRamRole, this.ramRolesTestEnum.inspectorRamRole],
      [this.ramRolesKeyEnum.managerRamRole]: this.isProd ? [this.ramRolesEnum.managerRamRole] : [this.ramRolesEnum.managerRamRole, this.ramRolesTestEnum.managerRamRole],
      [this.ramRolesKeyEnum.requester]: this.isProd ? [this.ramRolesEnum.requester] : [this.ramRolesEnum.requester, this.ramRolesTestEnum.requester],
      [this.ramRolesKeyEnum.supervisorRamRole]: this.isProd ? [this.ramRolesEnum.supervisorRamRole] : [this.ramRolesEnum.supervisorRamRole, this.ramRolesTestEnum.supervisorRamRole],
      [this.ramRolesKeyEnum.swoAuditorRamRole]: this.isProd ? [this.ramRolesEnum.swoAuditorRamRole] : [this.ramRolesEnum.swoAuditorRamRole, this.ramRolesTestEnum.swoAuditorRamRole],
      [this.ramRolesKeyEnum.vicePresidentRamRole]: this.isProd ? [this.ramRolesEnum.vicePresidentRamRole] : [this.ramRolesEnum.vicePresidentRamRole, this.ramRolesTestEnum.vicePresidentRamRole],
    };
  }

  private setRamRolesToCheck(): void {
    this.userHandler.getLoggedUser$.pipe(
      take(1)
    ).subscribe(
      loggedUser => {
        const loggedUserRoles = loggedUser?.roles;
        if (!loggedUserRoles) return;

        let userRoles: UserRolesModel = {};
        Object.entries(loggedUserRoles).map(
          ([key, value]) => {
            key = key.replace(RegexRules.getTextBeforeSlash, '');
            value = value.replace(RegexRules.getTextBeforeSlash, '');    
            userRoles[key] = value;
          }
        );
        
        this.loggedUserRoles = userRoles;
      }
    );
  }
}


import { createSelector } from '@ngrx/store';
import { CorrectionsReducer, CorrectionsStateModel } from '../reducer/corrections.reducer';

const selector = createSelector(CorrectionsReducer.correctionsDetailFeatureSelector, (state) => state);

const getProNbr = createSelector(selector, (state: CorrectionsStateModel) => state.proNbr);

const getCurrentRequestFormLockedInd = createSelector(selector, (state: CorrectionsStateModel) => state.isCurrentRequestFormLocked);

const getRequestFormType = createSelector(selector, (state: CorrectionsStateModel) => state.requestFormType);

const getTdcOptionRequestFormDisabled = createSelector(selector, (state: CorrectionsStateModel) => state.tdcOptionRequestFormDisabled);

const getCorrectionsBillCharges = createSelector(
  selector,
  (state: CorrectionsStateModel) => state.correctionsBillCharges
);

const getCorrectionsDetail = createSelector(selector, (state: CorrectionsStateModel) => state.correctionsDetail);

const getCorrectionsCorrectedDetail = createSelector(
  selector,
  (state: CorrectionsStateModel) => state.correctionsCorrectedDetail
);

const getCorrectionsHistoryDetail = createSelector(
  selector,
  (state: CorrectionsStateModel) => state.correctionsHistoryDetail
);

const getListCorrections = createSelector(
  selector,
  (state: CorrectionsStateModel) => state.correctionsMetadata
);

const getListCorrectionsReasonCategories = createSelector(
  selector,
  (state: CorrectionsStateModel) => state.correctionsMetadata?.editMetadataModel.reasonCategories
);

const getManualRatePreview = createSelector(
  selector,
  (state: CorrectionsStateModel) => state.manualRatePreview
);

const getAutoRatePreview = createSelector(
  selector,
  (state: CorrectionsStateModel) => state.autoRatePreview
);

const getListCorrectionsReasonDescriptions = createSelector(
  selector,
  (state: CorrectionsStateModel) => state.correctionsMetadata?.editMetadataModel.reasonDescriptions
);

const getLoadingGetBillCharges = createSelector(
  selector,
  (state: CorrectionsStateModel) => state.loadingGetBillCharges
);

const getCurrentRequestForm = createSelector(
  selector,
  (state: CorrectionsStateModel) => state.currentRequestForm
);

const getOriginalRequestForm = createSelector(
  selector,
  (state: CorrectionsStateModel) => state.originalRequestForm
);

const getRatingInformationDetail = createSelector(
  selector,
  (state: CorrectionsStateModel) => state.ratingInformationDetail
);

const updateCorrectionRequestLoading = createSelector(
  selector,
  (state: CorrectionsStateModel) => state.updateCorrectionRequestLoading
);

export const CorrectionsSelectors = {
  getProNbr,
  getCurrentRequestFormLockedInd,
  getRequestFormType,
  getTdcOptionRequestFormDisabled,
  getCorrectionsBillCharges,
  getCorrectionsDetail,
  getCorrectionsCorrectedDetail,
  getCorrectionsHistoryDetail,
  getListCorrections,
  getListCorrectionsReasonCategories,
  getListCorrectionsReasonDescriptions,
  getManualRatePreview,
  getAutoRatePreview,
  getLoadingGetBillCharges,
  getRatingInformationDetail,
  getCurrentRequestForm,
  getOriginalRequestForm,
  updateCorrectionRequestLoading
};

import { Injectable } from '@angular/core';
import {
  CorrectionsListMetadataModel,
  CorrectionsListMetadataQueueModel,
  CorrectionsListMetadataReasonCategoriesModel,
  CorrectionsListMetadataReasonDescriptionModel,
  CorrectionsMetadataModel,
} from '@shared/models/corrections/corrections-list-metadata.model';
import { ListCorrectionMetadataQuery, ListCorrectionMetadataResp } from '@xpo-ltl-2.0/sdk-billcorrection';

@Injectable()
export class CorrectionsListMetadataMapperService {
  constructor() {}

  getRequestForListCorrectionsMetaData(): ListCorrectionMetadataQuery {
    return new ListCorrectionMetadataQuery();
  }

  getMappedData(metadataApiResponse: [ListCorrectionMetadataResp, ListCorrectionMetadataResp, ListCorrectionMetadataResp]): CorrectionsMetadataModel {
    return {
      editMetadataModel: {
        queues: this.getQueueData(metadataApiResponse[0]),
        reasonCategories: this.getReasonCategories(metadataApiResponse[0]),
        reasonDescriptions: this.getReasonDescriptions(metadataApiResponse[0]),
      },
      reassignMetadataModel: {
        queues: this.getQueueData(metadataApiResponse[2]),
        reasonCategories: this.getReasonCategories(metadataApiResponse[2]),
        reasonDescriptions: this.getReasonDescriptions(metadataApiResponse[2]),
      },
      auditMetadataModel: {
        queues: this.getQueueData(metadataApiResponse[1]),
        reasonCategories: this.getReasonCategories(metadataApiResponse[1]),
        reasonDescriptions: this.getReasonDescriptions(metadataApiResponse[1]),
      },
      
    };
  }

  private getReasonCategories(
    metadataApiResponse: ListCorrectionMetadataResp
  ): CorrectionsListMetadataReasonCategoriesModel[] {
    if (!metadataApiResponse?.reasonCategories) return [];
    return metadataApiResponse.reasonCategories.map((reasonCategory) => ({
      code: reasonCategory.categoryCd,
      description: reasonCategory.categoryDescription,
    }));
  }

  private getReasonDescriptions(
    metadataApiResponse: ListCorrectionMetadataResp
  ): CorrectionsListMetadataReasonDescriptionModel[] {
    if (!metadataApiResponse?.reasons) return [];
    return metadataApiResponse.reasons.map((reasonDescription) => ({
      code: reasonDescription.reasonCd,
      description: reasonDescription.reasonDescription,
      reasonCategoryCode: reasonDescription.categoryCd,
    }));
  }

  private getQueueData(metadataApiResponse: ListCorrectionMetadataResp): CorrectionsListMetadataQueueModel[] {
    if (!metadataApiResponse?.correctionQueues) return [];
    return metadataApiResponse.correctionQueues.map((queue) => ({
      queueName: queue.queueName,
      queueCorpCd: queue.queueCorpCd,
      queueDescription: queue.queueDescription,
    }));
  }
}

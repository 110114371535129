export enum CorrectionsQueueSelectionTypeEnum {
  accessorials = 'accessorials',
  cwyAuditLvl1 = 'cwyAuditLvl1',
  cwyAuditLvl2 = 'cwyAuditLvl2',
  cwyAuditLvl3 = 'cwyAuditLvl3',
  cwyAuditLvl4 = 'cwyAuditLvl4',
  fia = 'fia',
  fiaCts599 = 'fiaCts599',
  generalCorrections = 'generalCorrections',
  inspection = 'inspection',
  manualAudit = 'manualAudit',
  massCorrections = 'massCorrections',
  mexico = 'mexico',
  miscellaneousHold = 'miscellaneousHold',
  offshore = 'offshore',
  onTimeWaranty = 'onTimeWaranty',
  priorityQueue1 = 'priorityQueue1',
  priorityQueue2 = 'priorityQueue2',
  reweigh = 'reweigh',
  sicWriteOff = 'sicWriteOff',
};

export enum CorrectionsErrorDialogLabelsEnum {
  cancelConfModalConfirmButton = 'ESCALATE',
  cancelConfModalDenyButton = 'CANCEL',
  cancelConfModalTitle = 'Escalation Required'
};

// ---------------------------------------------------------------
export enum CorrectionsFormEnum {
  accessorials = 'accessorials',
  advanceScac = 'advanceScac',
  amcAmount = 'amcAmount',
  beyondScac = 'beyondScac',
  billto = 'billto',
  cashCollected = 'cashCollected',
  cashPaid = 'cashPaid',
  cancelCharges = 'cancelCharges',
  chargeCode = 'chargeCode',
  codAmount = 'codAmount',
  commodities = 'commodities',
  consignee = 'consignee',
  customInBond = 'customInBond',
  extraInformation = 'extraInformation',
  discountAmount = 'discountAmount',
  guaranteed = 'guaranteed',
  measures = 'measures',
  miscellaneous = 'miscellaneous',
  reason = 'reason',
  invoicing = 'invoicing',
  auditorRemarks = 'auditorRemarks',
  requestInfo = 'requestInfo',
  revenues = 'revenues',
  sharedForm = 'sharedForm',
  shipper = 'shipper',
  shippingRemarks = 'shippingRemarks',
  sic = 'sic',
  tariff = 'tariff',
  timeDeliveryWindow = 'timeDeliveryWindow',
  weight = 'weight',
  auditEscalationInd = 'auditEscalationInd',
}

export enum CorrectionsRequestFormTypeEnum {
  tdc = 'tdc',
  default = 'default'
}

export enum CorrectionsFormViewEnum {
  auditor = 'AUDITOR',
  pending = 'PENDING',
  form = 'FORM'
}

import { Injectable } from '@angular/core';
import { CorrectionsCodAmountModel } from '@shared/models/corrections/corrections-form/cod-amount/corrections-cod-amount.model';
import { CorrectionsFormValueModel } from '@shared/models/corrections/corrections-form/corrections-form.model';
import { TransformCommonDataFieldsService } from '@shared/services/application-utils/transforms/common-data-fields/transform-common-data-fields.service';
import { GetAutoRatesPreviewQuery, GetAutoRatesPreviewRqst } from '@xpo-ltl-2.0/sdk-billcorrection';
import { AccessorialService, AsMatchedParty, ChargeToCd, MatchedPartyTypeCd, MiscLineItem, MiscLineItemCd, RatingShipmentRequest, TimeDateCritical } from '@xpo-ltl/sdk-common';
import { CorrectionsCreateRequestCommonMapperService } from '../../create-request/common/corrections-create-request-common-mapper.service';
import { CorrectionsPreviewRatesCommonRequestMapperService } from '../common/corrections-preview-rates-request-common-mapper.service';

@Injectable()
export class CorrectionsAutoRatesRequestMapperService {
  constructor(
    private transformCommonDataFieldsService: TransformCommonDataFieldsService,
    private correctionsPreviewRatesCommonRequestMapperService: CorrectionsPreviewRatesCommonRequestMapperService,
    private correctionsCreateRequestCommonMapperService: CorrectionsCreateRequestCommonMapperService
  ) {
  }

  getAutoRateRequest(correctionsForm: CorrectionsFormValueModel): GetAutoRatesPreviewRqst {
    const request = new GetAutoRatesPreviewRqst();
    request.removeBillToInd = true;
    request.shipper = this.getShipper(correctionsForm);
    request.accessorialServices = this.getAccesorials(correctionsForm);
    request.billTo = this.getBillTo(correctionsForm);
    request.commodities = this.correctionsPreviewRatesCommonRequestMapperService.getCommodities(correctionsForm);
    request.consignee = this.getConsignee(correctionsForm);
    request.miscLineItems = this.getMiscLineItems(correctionsForm);
    request.advanceCarrier = this.correctionsCreateRequestCommonMapperService.getAdvanceCarrier(correctionsForm);
    request.beyondCarrier = this.correctionsCreateRequestCommonMapperService.getBeyondCarrier(correctionsForm);

    request.ratingShipmentRequest = this.getRatingShipmentRequest(correctionsForm);
    request.timeDateCritical = this.getTimeDateCritical(correctionsForm);
    request.discountDescription = correctionsForm.discountAmount.delete ? undefined as any : correctionsForm.discountAmount.description;
    request.tariffEffectiveDate = correctionsForm.tariff.effectiveDate;
    return request;
  }

  getQueryParams(proNbr: string, shipInstId: number, correctionsForm: CorrectionsFormValueModel): GetAutoRatesPreviewQuery {
    const query = new GetAutoRatesPreviewQuery();
    query.pickupDate = correctionsForm.requestInfo.pickupDate;
    query.proNbr = proNbr;
    query.shipmentInstId = shipInstId;
    query.persistInd = 'false' as any;

    return query;
  }


  private getAccesorials(correctionsForm: CorrectionsFormValueModel): AccessorialService[] {
    const accesorial: AccessorialService[] = [];
    correctionsForm.accessorials.forEach(value => {
      if (value.delete) return;
      const acc = {...new AccessorialService()};
      acc.amount = this.transformCommonDataFieldsService.getNumberFromTextField(value.amount) as number;
      acc.description = value.description
      acc.sequenceNbr = value.sequenceNbr as string ?? undefined;
      acc.accessorialCd = value.code;
      acc.tariffsRate = this.transformCommonDataFieldsService.getNumberFromTextField(value.rate) as number;
      accesorial.push(acc)
    });
    return accesorial;
  }

  private getBillTo(correctionsForm: CorrectionsFormValueModel): AsMatchedParty {
    if (!correctionsForm.billto || !correctionsForm.billto.madCode || !correctionsForm.billto.customerNumber || correctionsForm.billto.deleteBillTo) {
      return undefined as any;
    }

    const billTo ={ ...new AsMatchedParty() };

    billTo.cisCustNbr = correctionsForm.billto.customerNumber != null ? correctionsForm.billto.customerNumber : (null as any);
    billTo.name1 = correctionsForm.billto.nameOne || (null as any);
    billTo.name2 = correctionsForm.billto.nameTwo || (null as any);
    billTo.address = correctionsForm.billto.address || (null as any);
    billTo.city = correctionsForm.billto.city || (null as any);
    billTo.stateCd = correctionsForm.billto.state || (null as any);
    billTo.countryCd = correctionsForm.billto.country || (null as any);
    billTo.zip6 = correctionsForm.billto.zipCode || (null as any);
    billTo.zip4RestUs = correctionsForm.billto.zipCodeComplementation || (null as any);
    billTo.typeCd = correctionsForm.chargeCode?.chargeCode === ChargeToCd.COLL ? MatchedPartyTypeCd.BILL_TO_INB : MatchedPartyTypeCd.BILL_TO_OTB
    return billTo;
  }

  private getConsignee(correctionsForm: CorrectionsFormValueModel): AsMatchedParty {
    if (!correctionsForm.consignee) return undefined as any;
    const consignee = {...new AsMatchedParty()};

    consignee.cisCustNbr = correctionsForm.consignee.customerNumber != null ? correctionsForm.consignee.customerNumber : (null as any);
    consignee.asMatchedMadCd = correctionsForm.consignee.madCode || (null as any);
    consignee.name1 = correctionsForm.consignee.nameOne || (null as any);
    consignee.name2 = correctionsForm.consignee.nameTwo || (null as any);
    consignee.address = correctionsForm.consignee.address || (null as any);
    consignee.city = correctionsForm.consignee.city || (null as any);
    consignee.stateCd = correctionsForm.consignee.state || (null as any);
    consignee.countryCd = correctionsForm.consignee.country || (null as any);
    consignee.zip6 = correctionsForm.consignee.zipCode || (null as any);
    consignee.zip4RestUs = correctionsForm.consignee.zipCodeComplementation || (null as any);
    consignee.typeCd = MatchedPartyTypeCd.CONS;
    consignee.creditStatusCd = correctionsForm.consignee.creditStatus || (null as any);
    return consignee;
  }

  private getRatingShipmentRequest(correctionsForm: CorrectionsFormValueModel): RatingShipmentRequest{
    const codAmt = this.transformCommonDataFieldsService.getNumberFromTextField(correctionsForm.codAmount.amount) as number;
    const totalPalletCnt = this.transformCommonDataFieldsService.getNumberFromTextField(correctionsForm.measures.palletCount) as number;
    const linealFootTotalNbr = this.transformCommonDataFieldsService.getNumberFromTextField(correctionsForm.measures.linealFeet) as number;
    const overrideDiscountPercentage = this.transformCommonDataFieldsService.getNumberFromTextField(correctionsForm.discountAmount.percentage) as number;
    const rating = new RatingShipmentRequest();

    rating.pickupDate = correctionsForm.requestInfo.pickupDate;
    rating.totalPalletCnt = this.isRequestDeleted(correctionsForm.measures.deletePalletCount, totalPalletCnt);
    rating.linealFootTotalNbr = this.isRequestDeleted(correctionsForm.measures.deleteLinealFeet, linealFootTotalNbr);
    rating.guaranteedInd = correctionsForm.guaranteed?.indicator;
    rating.overrideRatingTariff = this.correctionsCreateRequestCommonMapperService.getTariffCodeValue(correctionsForm.tariff.code, true) as string;
    rating.codAmt = this.isRequestDeleted(correctionsForm.codAmount.delete, codAmt);
    rating.chargeToCd = correctionsForm.chargeCode?.chargeCode;
    rating.destTerminalSic = correctionsForm.sic?.sicDestination;
    rating.origTerminalSic = correctionsForm.sic?.sicOrigin;
    rating.overrideDiscountPercentage = this.isRequestDeleted(correctionsForm.discountAmount.delete , overrideDiscountPercentage);
    
    return rating;
  }

  private isRequestDeleted(deleteValue: boolean | undefined, value: number): number {
    if (deleteValue) return undefined as any;
    else return value;
  }

  private getShipper(correctionsForm: CorrectionsFormValueModel): AsMatchedParty {
    if (!correctionsForm.shipper) return undefined as any;
    const shipper = {...new AsMatchedParty()};

    shipper.cisCustNbr = correctionsForm.shipper.customerNumber != null ? correctionsForm.shipper.customerNumber : (null as any);
    shipper.asMatchedMadCd = correctionsForm.shipper.madCode || (null as any);
    shipper.name1 = correctionsForm.shipper.nameOne || (null as any);
    shipper.name2 = correctionsForm.shipper.nameTwo || (null as any);
    shipper.address = correctionsForm.shipper.address || (null as any);
    shipper.city = correctionsForm.shipper.city || (null as any);
    shipper.stateCd = correctionsForm.shipper.state || (null as any);
    shipper.countryCd = correctionsForm.shipper.country || (null as any);
    shipper.zip6 = correctionsForm.shipper.zipCode || (null as any);
    shipper.zip4RestUs = correctionsForm.shipper.zipCodeComplementation || (null as any);
    shipper.typeCd = MatchedPartyTypeCd.SHPR;
    shipper.creditStatusCd = correctionsForm.shipper.creditStatus || (null as any);
    return shipper;
  }
  
  private getMiscLineItems(correctionsForm: CorrectionsFormValueModel): MiscLineItem[] {
    const miscellaneous: MiscLineItem[] = [];
    miscellaneous.push(
      {
        ...this.correctionsPreviewRatesCommonRequestMapperService.getAsDeficitWeight(correctionsForm)
      },
      {
        ...this.getCodAmount(correctionsForm.codAmount)
      }
    );
    let filteredArray = miscellaneous.filter(misc => {
      return misc.lineTypeCd !== undefined
    });
    return filteredArray;
  }
  
  private getCodAmount(codAmount: CorrectionsCodAmountModel): MiscLineItem {
    if ((!codAmount.description && !codAmount.amount) || codAmount.delete) return new MiscLineItem();
    return {
      ...new MiscLineItem,
      lineTypeCd: MiscLineItemCd.COD_AMT,
      amount: this.transformCommonDataFieldsService.getNumberFromTextField(codAmount.amount) as number,
      description: codAmount.description,
      sequenceNbr: codAmount.sequenceNbr
    };
  }

  // TODO: Disable tdc until the api to create a new request is implemented
  private getTimeDateCritical(correctionsForm: CorrectionsFormValueModel): TimeDateCritical {
    /* const tdcDateTypeCd = correctionsForm.timeDeliveryWindow.dateSelection;
    const tdcTimeTypeCd = correctionsForm.timeDeliveryWindow.timeSelection;

    let tdcDate1: string = undefined as any;
    let tdcDate2: string = undefined as any;

    let tdcTime1: string = undefined as any;
    let tdcTime2: string = undefined as any;

    const dateFormat = DateFormat.formRawValueDates;

    if (tdcDateTypeCd === CorrectionsDateTimeTypesEnum.on) {
      tdcDate1 = DateUtils.momentToString(correctionsForm.timeDeliveryWindow.initialOnDate, dateFormat);
    } else if (tdcDateTypeCd === CorrectionsDateTimeTypesEnum.by) {
      tdcDate1 = DateUtils.momentToString(correctionsForm.timeDeliveryWindow.initialByDate, dateFormat);
    } else if (tdcDateTypeCd === CorrectionsDateTimeTypesEnum.range) {
      tdcDate1 = DateUtils.momentToString(correctionsForm.timeDeliveryWindow.initialRangeDate, dateFormat);
      tdcDate2 = DateUtils.momentToString(correctionsForm.timeDeliveryWindow.endRangeDate, dateFormat);
    }

    if (tdcTimeTypeCd === CorrectionsDateTimeTypesEnum.before) {
      tdcTime1 = this.transformCommonDataFieldsService.getValueFromTimeField(correctionsForm.timeDeliveryWindow.initialBeforeTime);
    } else if (tdcTimeTypeCd === CorrectionsDateTimeTypesEnum.after) {
      tdcTime1 = this.transformCommonDataFieldsService.getValueFromTimeField(correctionsForm.timeDeliveryWindow.initialAfterTime);
    } else if (tdcTimeTypeCd === CorrectionsDateTimeTypesEnum.range) {
      tdcTime1 = this.transformCommonDataFieldsService.getValueFromTimeField(correctionsForm.timeDeliveryWindow.initialRangeTime);
      tdcTime2 = this.transformCommonDataFieldsService.getValueFromTimeField(correctionsForm.timeDeliveryWindow.endRangeTime);
    }*/

    return {
      ...new TimeDateCritical(),
      /* tdcDate1,
      tdcDate2,
      tdcDateTypeCd,
      tdcTime1,
      tdcTime2,
      tdcTimeTypeCd */
    };
  }
}

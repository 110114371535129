import { Injectable } from '@angular/core';
import { GetAutoRatesPreviewResp } from '@xpo-ltl-2.0/sdk-billcorrection';
import { MatchedPartyTypeCd, MiscLineItemCd } from '@xpo-ltl/sdk-common';
import { CorrectionsPreviewRatesModel, CorrectionsPreviewRevenuesModel, CorrectionsPreviewTotalsModel } from '@shared/models/corrections/preview-rates/corrections-preview-rates.model';
import { CorrectionsAccessorialRespModel, CorrectionsChargeCodeRespModel, CorrectionsCodAmountRespModel, CorrectionsCommoditiesRespModel, CorrectionsCustomerRespModel, CorrectionsDiscountAmountRespModel, CorrectionsPricingAgreementRespModel, CorrectionsSicRespModel, CorrectionsWeightLineRespModel } from '@shared/models/corrections/corrections-detail.model';
import { CorrectionsWeightFormTypeEnum } from '@shared/enums/corrections/corrections-form/weight/corrections-weight-form.enum';
import { CorrectionsPreviewRatesCommonMetadataMapperService } from '../common/corrections-preview-rates-common-metadata-mapper.service';

@Injectable()
export class CorrectionsAutoRatesMetadataMapperService {
  constructor(private correctionsPreviewRatesCommonMetadataMapperService: CorrectionsPreviewRatesCommonMetadataMapperService) {}

  getMappedDataForAuto(response: GetAutoRatesPreviewResp): CorrectionsPreviewRatesModel | undefined {
    const data = new CorrectionsPreviewRatesModel();
    if(!response.pickupDate) return undefined;
    data.pickupDate = response?.pickupDate;
    data.codAmount = this.getCodAmount(response);
    data.weightLine = this.getWeightLine(response);
    data.pricingRulesetAfterChanges = this.getPricingRuleset(response);
    data.chargeCode = this.getChargeCode(response);
    data.sic = this.getSic(response);
    data.shipper = this.getShipper(response);
    data.consignee = this.getConsignee(response);
    data.billTo = this.getBillTo(response);
    data.discounts = this.getDiscounts(response);
    data.commodities = this.getCommodities(response);
    data.accesorials = this.getAccesorials(response);
    data.revenues = this.getRevenues(response);
    data.totals = this.getTotals(response);
    data.rateStatusCd = response?.rateStatusCd;
    return data;
  }

  private getWeightLine(response: GetAutoRatesPreviewResp): CorrectionsWeightLineRespModel {
    const asWeight = response.miscLineItems.find(misc => misc.lineTypeCd === MiscLineItemCd.AS_WGT);
    const deficitWeight = response.miscLineItems.find(misc => misc.lineTypeCd === MiscLineItemCd.DEFICIT_WGT);
    return {
      deleteInd: false,
      typeWeightLine: asWeight ? CorrectionsWeightFormTypeEnum.asWeight : CorrectionsWeightFormTypeEnum.deficitWeight,
      asWeight: this.correctionsPreviewRatesCommonMetadataMapperService.getAsWeight(asWeight),
      deficitWeight: this.correctionsPreviewRatesCommonMetadataMapperService.getDeficitWeight(deficitWeight)
    };
  }

  private getCodAmount(response: GetAutoRatesPreviewResp): CorrectionsCodAmountRespModel {
    const codAmount = response.miscLineItems.find(misc => misc.lineTypeCd === MiscLineItemCd.COD_AMT);
    return {
      ... new CorrectionsCodAmountRespModel(),
      ...codAmount
    };
  }
  
  private getPricingRuleset(response: GetAutoRatesPreviewResp): CorrectionsPricingAgreementRespModel {
    return {
      ... new CorrectionsPricingAgreementRespModel(), 
      pricingRulesetNbr: response.pricingAgreement?.prcRulesetNbr,
      pricingAgreementNbrTxt: response.pricingAgreement?.prcAgreementNbrTxt,
      prcAgreementInstId: response.pricingAgreement?.prcAgreementInstId
    };
  }

  private getChargeCode(response: GetAutoRatesPreviewResp): CorrectionsChargeCodeRespModel {
    return {
      ... new CorrectionsChargeCodeRespModel(),
      chargeCode: response.chargeToCd
    };
  }

  private getSic(response: GetAutoRatesPreviewResp): CorrectionsSicRespModel {
    return {
      origin: response?.originTerminalSic,
      destination: response?.destinationTerminalSic
    };
  }
  
  private getShipper(response: GetAutoRatesPreviewResp): CorrectionsCustomerRespModel {
    return {
      address: response.shipper?.address,
      city: response.shipper?.city,
      chrgsResp: MatchedPartyTypeCd.BILL_TO_INB,
      customerNumber: response.shipper?.cisCustNbr,
      madCode: response.shipper?.asMatchedMadCd,
      country: response.shipper?.countryCd,
      name1: response.shipper?.name1,
      name2: response.shipper?.name2,
      state: response.shipper?.stateCd,
      zip: response.shipper?.zip6,
      zipRest: response.shipper?.zip4RestUs,
    };
  }

  private getConsignee(response: GetAutoRatesPreviewResp): CorrectionsCustomerRespModel {
    return {
      address: response.consignee?.address,
      city: response.consignee?.city,
      chrgsResp: MatchedPartyTypeCd.BILL_TO_INB,
      customerNumber: response.consignee?.cisCustNbr,
      madCode: response.consignee?.asMatchedMadCd,
      country: response.consignee?.countryCd,
      name1: response.consignee?.name1,
      name2: response.consignee?.name2,
      state: response.consignee?.stateCd,
      zip: response.consignee?.zip6,
      zipRest: response.consignee?.zip4RestUs,
    };
  }
  private getBillTo(response: GetAutoRatesPreviewResp): CorrectionsCustomerRespModel {
    return {
      address: response.billTo?.address,
      city: response.billTo?.city,
      chrgsResp: MatchedPartyTypeCd.BILL_TO_INB,
      customerNumber: response.billTo?.cisCustNbr,
      madCode: response.billTo?.asMatchedMadCd,
      country: response.billTo?.countryCd,
      name1: response.billTo?.name1,
      name2: response.billTo?.name2,
      state: response.billTo?.stateCd,
      zip: response.billTo?.zip6,
      zipRest: response.billTo?.zip4RestUs,
    };
  }

  private getDiscounts(response: GetAutoRatesPreviewResp): CorrectionsDiscountAmountRespModel {
    return {
      percentage: response.ratingResponse?.actualDiscountPct,
      description: response?.discountDescriptionResponse,
      amount: response.ratingTotal?.totalDiscountAmt
    };
  }
  
  private getCommodities(response: GetAutoRatesPreviewResp): CorrectionsCommoditiesRespModel[] {
    return response.commodities.map(commodity => {
      return {
        ...new CorrectionsCommoditiesRespModel(),
        pieces: commodity?.piecesCount,
        pkg: commodity?.packageCd,
        hazmat: commodity?.hazardousMtInd,
        description: commodity?.description,
        nmfc: commodity?.nmfcItemCd,
        class: commodity?.classType,
        weight: commodity?.weightLbs,
        rate: commodity?.tariffsRate,
        amount: commodity?.amount,
      } as CorrectionsCommoditiesRespModel;
    });
  }

  private getAccesorials(response: GetAutoRatesPreviewResp): CorrectionsAccessorialRespModel[] {
    return response.accessorialServices.map(acc => {
      return {
        ...new CorrectionsAccessorialRespModel(),
        sequenceNbr: acc?.sequenceNbr,
        code: acc?.accessorialCd,
        description: acc.accessorialCd + ' ' + (acc?.description ?? ''),
        rate: acc?.tariffsRate,
        amount: acc?.amount
      } as CorrectionsAccessorialRespModel;
    });
  }

  private getRevenues(response: GetAutoRatesPreviewResp): CorrectionsPreviewRevenuesModel {
    return {
      totalRevenue: response.ratingResponse?.ctsRevenueAmt,
      advanceRevenue: response.advanceCarrier?.chargeAmount,
      beyondRevenue: response.beyondCarrier?.chargeAmount
    };
  }

  private getTotals(response: GetAutoRatesPreviewResp): CorrectionsPreviewTotalsModel {
    return {
      totalPcs: response.commodities.reduce((acumulador, commodity) => acumulador + (commodity.piecesCount ?? 0), 0),
      totalWeight: response.ratingTotal.totalWeight,
      totalCharges: response.ratingTotal.totalChargeAmt,
      totalPercentage: response.ratingResponse.actualDiscountPct,
    };
  }
}

import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { CorrectionsGeneralRolesService } from '@shared/services/application-utils/user-roles/corrections-main-tab/corrections-general-roles.service';
import { RouterNavigationCorrectionsService } from '@shared/services/router-navigation/corrections/router-navigation-corrections.service';

@Injectable({
  providedIn: 'root'
})
export class AuditorFunctionGuard implements CanActivate {
  constructor(
    private correctionsGeneralRolesService: CorrectionsGeneralRolesService,
    private routerNavigationCorrectionsService: RouterNavigationCorrectionsService
  ) { }

  canActivate(): boolean {
      const displayAuditorFunctionTab = this.correctionsGeneralRolesService.displayAuditorFunctionTab();

      if (!displayAuditorFunctionTab) {
        console.info(`Info: You can't access to auditor function screen`);
        this.routerNavigationCorrectionsService.goToBillDetailsSearch();
      }

      return displayAuditorFunctionTab;
  }

}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CorrectionsBillDetailsNavTitle } from '@shared/enums/corrections/bill-details/corrections-bill-details-nav.enum';
import { TabNavBarModel } from '@shared/models/shared/tab-nav-bar.model';
import { CorrectionsRoutes } from '@shared/routes/corrections-routes.constant';
import { CorrectionsGeneralRolesService } from '@shared/services/application-utils/user-roles/corrections-main-tab/corrections-general-roles.service';
import { CorrectionsTabsService } from '@shared/services/features/corrections-tabs/corrections-tabs.service';
import { ProNumberService } from '@shared/services/features/pro-number/pro-number.service';
import { RouterNavigationCorrectionsService } from '@shared/services/router-navigation/corrections/router-navigation-corrections.service';
import { SubCollection } from '@shared/utils/rx/sub-collection';

@Component({
  selector: 'app-corrections',
  templateUrl: './corrections.component.html',
  styleUrls: ['./corrections.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CorrectionsComponent implements OnDestroy {
  navLinks: TabNavBarModel[] = [];

  displaySideNav: boolean;
  displayAuditorFunctionTab = this.correctionsMainTabService.displayAuditorFunctionTab();

  subs$ = new SubCollection();

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private routerNavigationCorrectionsService: RouterNavigationCorrectionsService,
    private correctionsMainTabService: CorrectionsGeneralRolesService,
    private proNumberService: ProNumberService,
    private correctionsTabsService: CorrectionsTabsService,
  ) {
    this.changeRouter();
  }

  ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }

  setTabLinks(): void {
    this.navLinks = [
      {
        label: CorrectionsBillDetailsNavTitle.first,
        path: CorrectionsRoutes.billDetail.main,
      }
    ];

    if (this.proNumberService.doesProNumberExist()) {
      const requestFormTab = {
        label: CorrectionsBillDetailsNavTitle.second,
        path: CorrectionsRoutes.form.main,
      };
      this.navLinks.push(requestFormTab);
    }

    if (this.displayAuditorFunctionTab) {
      const auditorFunctionTab = {
        label: CorrectionsBillDetailsNavTitle.third,
        path: CorrectionsRoutes.auditorFunction.main,
      };

      this.navLinks.push(auditorFunctionTab);
    }
  }

  setDisplaySideNavValue(): void {
    this.displaySideNav = !this.routerNavigationCorrectionsService.isItTheMainScreen();
  }

  changeRouter(): void {
    this.subs$.add = this.router.events.subscribe(_ => {
      this.setDisplaySideNavValue();
      this.setTabLinks();
      this.cdr.detectChanges();
    });
  }

  changeRoute(link: TabNavBarModel): void {
    this.correctionsTabsService.navigateBetweenTabs(link.label as CorrectionsBillDetailsNavTitle);
  }
}

import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { UserPreferenceActions } from '../actions/user-preference.actions';
import { UserPreferenceEnum } from '@shared/enums/common-data/user-preference.enum';
import { UserPreferenceResultType } from '@shared/types/general/user-preference.types';

const userPreferenceFeatureKey = 'userPreference';
const userPreferenceFeatureSelector = createFeatureSelector<UserPreferenceState>(userPreferenceFeatureKey);

export interface UserPreferenceState {
  [UserPreferenceEnum.componentName]: UserPreferenceResultType | undefined;
}

const userPreferenceInitState: UserPreferenceState = {
  [UserPreferenceEnum.componentName]: {
    defaultQueue: undefined,
  },
};

const reducer = createReducer(
  userPreferenceInitState,
  on(UserPreferenceActions.getUserPreference, (state, { componentName }) => {
    return {
      ...state,
      [componentName]: userPreferenceInitState[componentName],
    };
  }),
  on(
    UserPreferenceActions.getUserPreferenceSuccess,
    UserPreferenceActions.updateUserPreferenceSuccess,
    (state, { componentName, preferences }) => {
      return {
        ...state,
        [componentName]: preferences,
      };
    }
  )
);

export const UserPreferenceReducer = {
  reducer,
  userPreferenceFeatureKey,
  userPreferenceFeatureSelector,
  userPreferenceInitState,
};

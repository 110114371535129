import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { RequestActionTypeCd } from '@shared/enums/common-data/request-action-type.enum';
import { CorrectionsRequestFormTypeEnum } from '@shared/enums/corrections/corrections-form/corrections-form.enum';
import { CorrectionsFormValueModel, CorrectionsTdcFormValueModel } from '@shared/models/corrections/corrections-form/corrections-form.model';
import { CorrectionRequestsAndHistoryQueryModel } from '@shared/models/corrections/corrections-request-and-history.model';
import { PendingRequestStoreModel } from '@shared/models/shared/store/pending-request-store.model';
import { UnratedProService } from '@shared/services/apis-errors/unrated-pro/unrated-pro.service';
import { BillTypeCd } from '@xpo-ltl/sdk-common';
import { map } from 'rxjs/operators';
import { CorrectionsActions } from '../actions/corrections.actions';
import { CorrectionsSelectors } from '../selectors/corrections.selectors';

@Injectable()
export class CorrectionsHandler {
  // ACTIONS *****************************************************************************************
  getDetailsSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.getDetailsSuccess),
    map((action) => action.correctionsDetail)
  );

  getDetailsFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.getDetailsFail),
    map((action) => action.errors)
  );

  getBillChargesSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.getBillChargesSuccess),
    map((action) => action.billCharges)
  );

  getBillChargesFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.getBillChargesFail),
    map((action) => action.errors)
  );

  getCustomerSicOverrideSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.getCustomerSicOverrideSuccess),
    map((action) => action.customerSicOverride)
  );

  getCustomerSicOverrideFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.getCustomerSicOverrideFail),
    map((action) => action.errors)
  );

  getHistoryDetailsSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.getHistoryDetailsSuccess),
    map((action) => action.correctionsDetail)
  );

  getHistoryDetailsFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.getHistoryDetailsFail),
    map((action) => action.errors)
  );

  getAutoRatePreviewSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.getAutoRatePreviewSuccess),
    map((action) => action.autoRatesPreview)
  );

  getAutoRatePreviewFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.getAutoRatePreviewFail),
    map((action) => action.errors)
  );

  getManualRatePreviewSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.getManualRatePreviewSuccess),
    map((action) => action.manualRatesPreview)
  );

  getManualRatePreviewFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.getManualRatePreviewFail),
    map((action) => action.errors)
  );

  getListCorrectionsMetaDataSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.getListCorrectionsMetaDataSuccess),
    map((action) => action.correctionsMetadata)
  );

  getListCorrectionsMetaDataFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.getListCorrectionsMetaDataFail),
    map((action) => action.errors)
  );

  getRequestAndHistorySuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.getRequestAndHistorySuccess),
    map((action) => action.correctionsRequestAndHistory)
  );

  getRequestAndHistoryFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.getRequestAndHistoryFail),
    map((action) => action.errors)
  );

  getRequestAuditInfoSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.getRequestAuditInfoSuccess),
    map((action) => action.correctionsRequestAndHistory)
  );

  getRequestAuditInfoFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.getRequestAuditInfoFail),
    map((action) => action.errors)
  );

  getAssociatedBillToSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.getAssociatedBillToSuccess),
    map((action) => action.associatedBillTo)
  );

  getAssociatedBillToFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.getAssociatedBillToFail),
    map((action) => action.errors)
  );

  getRatingInformationSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.getRatingInformationSuccess),
    map(action => action.ratingInformationResp)
  );

  getRatingInformationFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.getRatingInformationFail),
    map((action) => action.errors)
  );


  getCustomerCreditStatusSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.getCustomerCreditStatusSuccess),
    map((action) => action.creditStatus)
  );

  getCustomerCreditStatusFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.getCustomerCreditStatusFail),
    map((action) => action.errors)
  );

  getPendingCorrectionDetailsSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.getPendingCorrectionDetailsSuccess)
  );

  getPendingCorrectionDetailsFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.getPendingCorrectionDetailsFail),
    map((action) => action.errors)
  );

  getRequestQueueSummariesSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.getRequestQueueSummariesSuccess),
    map((action) => action.correctionsRequestQueueSummaries)
  );

  getRequestQueueSummariesFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.getRequestQueueSummariesFail),
    map((action) => action.errors)
  );

  deleteCorrectionsSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.deleteCorrectionsSuccess));

  deleteCorrectionsFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.deleteCorrectionsFail),
    map((action) => action.errors)
  );

  submitRequestSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.submitRequestSuccess),
    map((action) => action.response)
  );

  submitRequestFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.submitRequestFail),
    map((action) => action.errors)
  );

  submitTdcRequestSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.submitTdcRequestSuccess),
    map((action) => action.response)
  );

  submitTdcRequestFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.submitTdcRequestFail),
    map((action) => action.errors)
  );

  submitAuditorRequestSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.submitAuditorRequestSuccess),
    map(action => action.response)
  );

  submitAuditorRequestFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.submitAuditorRequestFail),
    map((action) => action.errors)
  );

  submitTdcAuditorRequestSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.submitTdcAuditorRequestSuccess),
    map(action => action.response)
  );

  submitTdcAuditorRequestFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.submitTdcAuditorRequestFail),
    map((action) => action.errors)
  );

  updateUnlockCorrectionRequestStatusSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.updateUnlockCorrectionRequestStatusSuccess)
  );

  updateUnlockCorrectionRequestStatusFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.updateUnlockCorrectionRequestStatusFail),
    map((action) => action.errors)
  );

  updateQueueForCorrectionRequestsSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.updateQueueForCorrectionRequestsSuccess),
    map((action) => action.response)
  );

  updateQueueForCorrectionRequestsFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.updateQueueForCorrectionRequestsFail),
    map((action) => action.errors)
  );

  resubmitRequestSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.resubmitRequestSuccess),
    map((action) => action.response)
  );
  resubmitRequestFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.resubmitRequestFail),
    map((action) => action.errors)
  );

  resubmitTdcRequestSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.resubmitTdcRequestSuccess),
    map((action) => action.response)
  );

  resubmitTdcRequestFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.resubmitTdcRequestFail),
    map((action) => action.errors)
  );

  // SELECTORS *****************************************************************************************
  getProNbr$ = this.store$.pipe(select(CorrectionsSelectors.getProNbr));

  getCurrentRequestFormLockedInd$ = this.store$.pipe(select(CorrectionsSelectors.getCurrentRequestFormLockedInd));

  getRequestFormType$ = this.store$.pipe(select(CorrectionsSelectors.getRequestFormType));

  getTdcOptionRequestFormDisabled$ = this.store$.pipe(select(CorrectionsSelectors.getTdcOptionRequestFormDisabled));

  getCorrectionsBillCharges$ = this.store$.pipe(select(CorrectionsSelectors.getCorrectionsBillCharges));

  getCorrectionsDetail$ = this.store$.pipe(select(CorrectionsSelectors.getCorrectionsDetail));

  getCorrectionsCorrectedDetail$ = this.store$.pipe(select(CorrectionsSelectors.getCorrectionsCorrectedDetail));

  getCorrectionsHistoryDetail$ = this.store$.pipe(select(CorrectionsSelectors.getCorrectionsHistoryDetail));

  getListCorrections$ = this.store$.pipe(select(CorrectionsSelectors.getListCorrections));

  getAutoRatePreview$ = this.store$.pipe(select(CorrectionsSelectors.getAutoRatePreview));

  getManualRatePreview$ = this.store$.pipe(select(CorrectionsSelectors.getManualRatePreview));

  getListCorrectionsReasonCategories$ = this.store$.pipe(
    select(CorrectionsSelectors.getListCorrectionsReasonCategories)
  );

  getListCorrectionsReasonDescriptions$ = this.store$.pipe(
    select(CorrectionsSelectors.getListCorrectionsReasonDescriptions)
  );

  getLoadingGetBillCharges$ = this.store$.pipe(select(CorrectionsSelectors.getLoadingGetBillCharges));

  getCurrentRequestForm$ = this.store$.pipe(select(CorrectionsSelectors.getCurrentRequestForm));

  getOriginalRequestForm$ = this.store$.pipe(select(CorrectionsSelectors.getOriginalRequestForm));

  getRatingInformationDetail$ = this.store$.pipe(select(CorrectionsSelectors.getRatingInformationDetail));

  updateCorrectionRequestLoading$ = this.store$.pipe(select(CorrectionsSelectors.updateCorrectionRequestLoading));

  constructor(
    private store$: Store,
    private actions$: Actions,
    private unratedProService: UnratedProService
  ) { }

  setProNbr(proNbr: string): void {
    this.store$.dispatch(CorrectionsActions.setProNbr({ proNbr }));
  }

  setCurrentRequestFormLockedInd(lockedInd: boolean): void {
    this.store$.dispatch(CorrectionsActions.setCurrentRequestFormLockedInd({ lockedInd }));
  }

  setRequestFormType(requestFormType: CorrectionsRequestFormTypeEnum): void {
    this.store$.dispatch(CorrectionsActions.setRequestFormType({ requestFormType }));
  }

  setDisableTdcOptionRequestForm(optionDisabled: boolean): void {
    this.store$.dispatch(CorrectionsActions.setDisableTdcOptionRequestForm({ optionDisabled }));
  }

  clearProNbr(): void {
    this.store$.dispatch(CorrectionsActions.clearProNbr());
  }

  getDetails(proNbr: string, errorActionFn: Function = this.getErrorFnForShipment(proNbr)): void {
    this.store$.dispatch(CorrectionsActions.getDetails({ proNbr, errorActionFn }));
  }

  getBillCharges(proNbr: string, pickupDate: string): void {
    this.store$.dispatch(CorrectionsActions.getBillCharges({ proNbr, pickupDate }));
  }

  getCustomerSicOverride(customerNbr: number): void {
    this.store$.dispatch(CorrectionsActions.getCustomerSicOverride({ customerNbr }));
  }

  getHistoryDetails(proNbr: string, errorActionFn: Function = this.getErrorFnForShipment(proNbr)): void {
    this.store$.dispatch(CorrectionsActions.getHistoryDetails({ proNbr, errorActionFn}));
  }

  getRatingInformationDetails(shipmentInstId: number): void {
    this.store$.dispatch(CorrectionsActions.getRatingInformation({ shipmentInstId }));
  }

  getAutoRatePreview(proNbr: string, shipInstId: number, correctionsForm: CorrectionsFormValueModel): void {
    this.store$.dispatch(CorrectionsActions.getAutoRatePreview({ proNbr, shipInstId, correctionsForm }));
  }

  getManualRatePreview(proNbr: string, shipInstId: number, correctionsForm: CorrectionsFormValueModel): void {
    this.store$.dispatch(CorrectionsActions.getManualRatePreview({ proNbr, shipInstId, correctionsForm }));
  }

  getListCorrectionsMetaData(tdcInd: boolean, loadingOverlayEnabled: boolean = false): void {
    this.store$.dispatch(CorrectionsActions.getListCorrectionsMetaData({ tdcInd, loadingOverlayEnabled }));
  }

  getRequestAndHistory(correctionRequestsAndHistoryQuery: CorrectionRequestsAndHistoryQueryModel): void {
    this.store$.dispatch(CorrectionsActions.getRequestAndHistory({ correctionRequestsAndHistoryQuery }));
  }

  getRequestAuditInfo(requestId: number, includeHistoryInd = true): void {
    this.store$.dispatch(CorrectionsActions.getRequestAuditInfo({ requestId, includeHistoryInd }));
  }

  getAssociatedBillTo(customerNumber: number, billCd: BillTypeCd): void {
    this.store$.dispatch(CorrectionsActions.getAssociatedBillTo({ customerNumber, billCd }));
  }

  getCustomerCreditStatus(customerNumber: number): void {
    this.store$.dispatch(CorrectionsActions.getCustomerCreditStatus({ customerNumber }));
  }

  getPendingCorrectionDetails(pendingRequestStore: PendingRequestStoreModel, errorActionFn: Function = this.getErrorFnForShipment(pendingRequestStore.proNbr)): void {
    this.store$.dispatch(CorrectionsActions.getPendingCorrectionDetails({ pendingRequestStore, errorActionFn }));
  }

  getRequestQueueSummaries(loadingOverlayEnabled: boolean = false): void {
    this.store$.dispatch(CorrectionsActions.getRequestQueueSummaries({ loadingOverlayEnabled }));
  }

  deleteCorrections(requestIds: number[]): void {
    this.store$.dispatch(CorrectionsActions.deleteCorrections({ requestIds }));
  }

  setCurrentRequestForm(currentRequestForm: CorrectionsFormValueModel): void {
    this.store$.dispatch(CorrectionsActions.setCurrentRequestForm({ currentRequestForm }));
  }

  setOriginalRequestForm(originalRequestForm: CorrectionsFormValueModel): void {
    this.store$.dispatch(CorrectionsActions.setOriginalRequestForm({ originalRequestForm }));
  }

  submitRequest(correctionsForm: CorrectionsFormValueModel, originalForm: CorrectionsFormValueModel): void {
    this.store$.dispatch(CorrectionsActions.submitRequest({ correctionsForm, originalForm }));
  }

  submitTdcRequest(tdcCorrectionsForm: CorrectionsTdcFormValueModel): void {
    this.store$.dispatch(CorrectionsActions.submitTdcRequest({ tdcCorrectionsForm }));
  }

  submitAuditorRequest(correctionsForm: CorrectionsFormValueModel, originalForm: CorrectionsFormValueModel, requestActionTypeCd: RequestActionTypeCd): void {
    this.store$.dispatch(CorrectionsActions.submitAuditorRequest({ correctionsForm, originalForm, requestActionTypeCd }));
  }

  submitTdcAuditorRequest(tdcCorrectionsForm: CorrectionsTdcFormValueModel, requestActionTypeCd: RequestActionTypeCd): void {
    this.store$.dispatch(
      CorrectionsActions.submitTdcAuditorRequest({ tdcCorrectionsForm, requestActionTypeCd })
    );
  }

  resubmitRequest(correctionsForm: CorrectionsFormValueModel, originalForm: CorrectionsFormValueModel): void {
    this.store$.dispatch(
      CorrectionsActions.resubmitRequest({ correctionsForm, originalForm })
    );
  }

  resubmitTdcRequest(tdcCorrectionsForm: CorrectionsTdcFormValueModel): void {
    this.store$.dispatch(CorrectionsActions.resubmitTdcRequest({ tdcCorrectionsForm }));
  }

  updateUnlockCorrectionRequestStatus(
    correctionRequestInstIds: number[]
  ): void {
    this.store$.dispatch(
      CorrectionsActions.updateUnlockCorrectionRequestStatus({
        correctionRequestInstIds,
      })
    );
  }

  updateQueueForCorrectionRequests(
    correctionRequestInstIds: number[],
    queueName: string
  ): void {
    this.store$.dispatch(
      CorrectionsActions.updateQueueForCorrectionRequests({
        correctionRequestInstIds,
        queueName,
      })
    );
  }

  private getErrorFnForShipment(proNbr: string, redirectToMainScreen = true): Function {
    return this.unratedProService.handleUnratedPro({ proNbr, redirectToMainScreen });
  }
}

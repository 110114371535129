import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '@shared/models/shared/error.model';
import { UserPreferenceComponentNameType, UserPreferenceResultType } from '@shared/types/general/user-preference.types';

const getUserPreference = createAction(
  '[UserPreferenceActions] Get user preference data',
  props<{ componentName: UserPreferenceComponentNameType }>()
);
const getUserPreferenceSuccess = createAction(
  '[UserPreferenceActions] Get user preference data Success',
  props<{ componentName: UserPreferenceComponentNameType; preferences: UserPreferenceResultType | undefined }>()
);
const getUserPreferenceFail = createAction(
  '[UserPreferenceActions] Get user preference data Fail',
  props<{ componentName: UserPreferenceComponentNameType; errors: ErrorModel[] }>()
);

const updateUserPreference = createAction(
  '[UserPreferenceActions] Update user preference data',
  props<{ componentName: UserPreferenceComponentNameType; preferences: UserPreferenceResultType }>()
);
const updateUserPreferenceSuccess = createAction(
  '[UserPreferenceActions] Update user preference data Success',
  props<{ componentName: UserPreferenceComponentNameType; preferences: UserPreferenceResultType }>()
);
const updateUserPreferenceFail = createAction(
  '[UserPreferenceActions] Update user preference data Fail',
  props<{ componentName: UserPreferenceComponentNameType; errors: ErrorModel[] }>()
);

export const UserPreferenceActions = {
  getUserPreference,
  getUserPreferenceSuccess,
  getUserPreferenceFail,
  updateUserPreference,
  updateUserPreferenceSuccess,
  updateUserPreferenceFail,
};

import { Injectable } from '@angular/core';
import { CorrectionsHandler } from '@app-store/corrections/handler/corrections.handler';
import { take } from 'rxjs/operators';

@Injectable()
export class ProNumberService {

  constructor(
    private correctionsHandler: CorrectionsHandler
  ) {}

  doesProNumberExist(): boolean {
    let proNbr = '';
    this.correctionsHandler.getProNbr$.pipe(take(1)).subscribe((proNbrFromStore) => (proNbr = proNbrFromStore));
    return !!proNbr;
  }
}

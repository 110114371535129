import { Injectable } from '@angular/core';
import { CorrectionsHandler } from '@app-store/corrections/handler/corrections.handler';
import { CorrectionsBillDetailsNavTitle } from '@shared/enums/corrections/bill-details/corrections-bill-details-nav.enum';
import { RouterNavigationCorrectionsService } from '@shared/services/router-navigation/corrections/router-navigation-corrections.service';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CorrectionsTabsService {

  constructor(
    private correctionsHandler: CorrectionsHandler,
    private routerNavigationCorrectionsService: RouterNavigationCorrectionsService
  ) { }

  navigateBetweenTabs(tabLabelValue: CorrectionsBillDetailsNavTitle): void {
    let proNbr = '';
    this.correctionsHandler.getProNbr$.pipe(take(1)).subscribe((proNbrFromStore) => (proNbr = proNbrFromStore));

    if (tabLabelValue === CorrectionsBillDetailsNavTitle.first) {
      proNbr ? this.routerNavigationCorrectionsService.goToBillDetailsContent(proNbr) : this.routerNavigationCorrectionsService.goToBillDetailsSearch();
    } else if (tabLabelValue === CorrectionsBillDetailsNavTitle.second) {
      this.routerNavigationCorrectionsService.goToCorrectionsForm(proNbr);
    }
  }
}

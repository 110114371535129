export enum CorrectionsSicFormEnum {
  origin = 'sicOrigin',
  destination = 'sicDestination'
};

export enum CorrectionsSicFormLabelEnum {
  origin = 'Orig SIC',
  destination = 'Dest SIC',
};

export enum CorrectionsSicFormIdsEnum {
  origin = 'originSic',
  destination = 'destinationSic',
};

export enum CorrectionsSicFormTitlesEnum {
  origin = 'Origin SIC',
  destination = 'Destination SIC',
  originDestination = 'Origin/ Destination SIC'
};

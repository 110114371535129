import { Injectable } from '@angular/core';
import { CorrectionsBillChargesModel } from '@shared/models/corrections/bill-payment/corrections-bill-charges.model';
import { ListArPaymentDetailsByProQuery, ListArPaymentDetailsByProResp } from '@xpo-ltl-2.0/sdk-accountsreceivable';

@Injectable()
export class CorrectionsAccountsReceivableMapperService {
  constructor() {}

  getRequest(proNbr: string, pickupDate: string): ListArPaymentDetailsByProQuery {
    const queryParams: ListArPaymentDetailsByProQuery = {
      proNbr,
      pickupDate,
    };
    return queryParams;
  }

  getBillChargesFromApi(listArPaymentDetailsByProResp: ListArPaymentDetailsByProResp): CorrectionsBillChargesModel {
    const correctionsBillCharges = new CorrectionsBillChargesModel();
    correctionsBillCharges.adjustments = 0;
    correctionsBillCharges.balanceDue = 0;
    correctionsBillCharges.payments = 0;
    correctionsBillCharges.paymentDetails = listArPaymentDetailsByProResp;

    listArPaymentDetailsByProResp?.arPaymentDetails.reduce((correctionsBillCharges, arPaymentDetail) => {
      correctionsBillCharges.balanceDue += arPaymentDetail.balanceDueAmt || 0;
      correctionsBillCharges.adjustments += arPaymentDetail.totalAdjustmentAmt || 0;
      if (arPaymentDetail.totalPaymentAmt > 0) {
        correctionsBillCharges.payments += arPaymentDetail.totalPaymentAmt;
      }
      return correctionsBillCharges;
    }, correctionsBillCharges);

    return correctionsBillCharges;
  }
}

export enum ConfigManagerProperties {
  buildVersion = 'buildVersion',
  production = 'production',
  isPreProd = 'isPreProd',
  apiUrl = 'apiUrl',
  scopeOptions = 'scopeOptions',
  secretToken = 'secretToken',
  appName = 'appName',
  region = 'region',
  accountsreceivableApiEndpoint = 'accountsreceivableApiEndpoint',
  apimetadataApiEndpoint = 'apimetadataApiEndpoint',
  appmetadataApiEndpoint = 'appmetadataApiEndpoint',
  aemApiEndpoint = 'aemApiEndpoint',
  batchApiEndpoint = 'batchApiEndpoint',
  billofladingApiEndpoint = 'billofladingApiEndpoint',
  claimsApiEndpoint = 'claimsApiEndpoint',
  customerApiEndpoint = 'customerApiEndpoint',
  elasticsearchApiEndpoint = 'elasticsearchApiEndpoint',
  equipmentApiEndpoint = 'equipmentApiEndpoint',
  freightflowApiEndpoint = 'freightflowApiEndpoint',
  freightmovementApiEndpoint = 'freightmovementApiEndpoint',
  humanresourceApiEndpoint = 'humanresourceApiEndpoint',
  imageCorpCode = 'imageCorpCode',
  infrastructureApiEndpoint = 'infrastructureApiEndpoint',
  invoiceApiEndpoint = 'invoiceApiEndpoint',
  linehaulpayformApiEndpoint = 'linehaulpayformApiEndpoint',
  locationApiEndpoint = 'locationApiEndpoint',
  loggingApiEndpoint = 'loggingApiEndpoint',
  membershipApiEndpoint = 'membershipApiEndpoint',
  ordermanagementApiEndpoint = 'ordermanagementApiEndpoint',
  payrollApiEndpoint = 'payrollApiEndpoint',
  pricingApiEndpoint = 'pricingApiEndpoint',
  ratingApiEndpoint = 'ratingApiEndpoint',
  referenceApiEndpoint = 'referenceApiEndpoint',
  shipmentApiEndpoint = 'shipmentApiEndpoint',
  shipmentinspectionApiEndpoint = 'shipmentinspectionApiEndpoint',
  shipmentodsApiEndpoint = 'shipmentodsApiEndpoint',
  shippingtoolsApiEndpoint = 'shippingtoolsApiEndpoint',
  userpreferenceApiEndpoint = 'userpreferenceApiEndpoint',
  errorToastDuration = 'errorToastDuration',
  successToastDuration = 'successToastDuration',
  feedbackToAddress = 'feedbackToAddress',
  feedbackSubject = 'feedbackSubject',
  logStatsForPaths = 'logStatsForPaths',
  logResponseBodyForPaths = 'logResponseBodyForPaths',
  logRequestBodyForPaths = 'logRequestBodyForPaths',
  logExclusionForPaths = 'logExclusionForPaths',
  logOrganization = 'logOrganization',
  logLayer = 'logLayer',
  logAppName = 'logAppName',
  logProjectName = 'logProjectName',
  logServicePerformance = 'logServicePerformance',
  logUnhandledErrors = 'logUnhandledErrors',
  esLogLevel = 'esLogLevel',
  authVersion = 'authVersion',
  authAppCode = 'authAppCode',
  authAppRootUri = 'authAppRootUri',
  authEnvironment = 'authEnvironment',
  authScopes = 'authScopes',
  authIsProd = 'authIsProd',
  authGrantType = 'authGrantType',
  navigatorUrl = 'navigatorUrl',
  prodUrl = 'prodUrl',
  appSwitcherApps = 'appSwitcherApps',
  shipmentManagementUrl = 'shipmentManagementUrl',
  shiplifyUrl = 'shiplifyUrl',
  edgeDynamicPricingUrl = 'edgeDynamicPricingUrl',
  edgeReweighUrl = 'edgeReweighUrl',
  tariffArchiveUrl = 'tariffArchiveUrl',
  edgeTariffManagementUrl = 'edgeTariffManagementUrl',
  plDocsUrl = 'plDocsUrl',
  ediSearchUrl = 'ediSearchUrl',
  edgeCustomerRulesUrl = 'edgeCustomerRulesUrl',
  edgeOrderEntryUrl = 'edgeOrderEntryUrl',
  classItUrl = 'classItUrl',
  densityClassCalculatorWebUrl = 'densityClassCalculatorWebUrl'
};

import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { UserPreferenceActions } from '../actions/user-preference.actions';
import { UserPreferenceSelectors } from '../selectors/user-preference.selector';
import { UserPreferenceComponentNameType, UserPreferenceResultType } from '@shared/types/general/user-preference.types';

@Injectable()
export class UserPreferenceHandlerService {
  correctionsUserPreferencesSelector$ = this.store$.pipe(select(UserPreferenceSelectors.correctionsUserPreferences));

  constructor(public store$: Store, public actions$: Actions) {}

  getUserPreference(componentName: UserPreferenceComponentNameType): void {
    this.store$.dispatch(UserPreferenceActions.getUserPreference({ componentName }));
  }

  updateUserPreference(componentName: UserPreferenceComponentNameType, preferences: UserPreferenceResultType): void {
    this.store$.dispatch(UserPreferenceActions.updateUserPreference({ componentName, preferences }));
  }
}

import { Injectable } from '@angular/core';
import { CorrectionsWeightFormTypeEnum } from '@shared/enums/corrections/corrections-form/weight/corrections-weight-form.enum';
import { CorrectionsAccessorialRespModel } from '@shared/models/corrections/corrections-detail.model';
import { CorrectionsAccessorialsModel } from '@shared/models/corrections/corrections-form/accessorials/corrections-accessorials.model';
import { CorrectionsAdvanceScacModel } from '@shared/models/corrections/corrections-form/advance-scac/corrections-advance-scac.model';
import { CorrectionsAmcAmountModel } from '@shared/models/corrections/corrections-form/amc-amount/corrections-amc-amount.model';
import { CorrectionsBeyondScacModel } from '@shared/models/corrections/corrections-form/beyond-scac/corrections-beyond-scac.model';
import { CorrectionsBillToModel } from '@shared/models/corrections/corrections-form/billto/corrections-billto.model';
import { CorrectionsCancelChargesModel } from '@shared/models/corrections/corrections-form/cancel-charges/corrections-cancel-charges.model';
import { CorrectionsCashCollectedFormValuesModel } from '@shared/models/corrections/corrections-form/cash-collected/corrections-cash-collected.model';
import { CorrectionsCashPaidModel } from '@shared/models/corrections/corrections-form/cash-paid/corrections-cash-paid.model';
import { CorrectionsChargeCodeModel } from '@shared/models/corrections/corrections-form/charge-code/corrections-charge-code.model';
import { CorrectionsCodAmountModel } from '@shared/models/corrections/corrections-form/cod-amount/corrections-cod-amount.model';
import { CorrectionCommoditiesModel } from '@shared/models/corrections/corrections-form/commodities/corrections-commodities.model';
import { CorrectionsConsigneeModel } from '@shared/models/corrections/corrections-form/consignee/corrections-consignee.model';
import { CorrectionsFormValueModel } from '@shared/models/corrections/corrections-form/corrections-form.model';
import { CorrectionsCustomInBondModel } from '@shared/models/corrections/corrections-form/custom-in-bond/corrections-custom-in-bond.model';
import { CorrectionsDiscountAmountModel } from '@shared/models/corrections/corrections-form/discount-amount/corrections-discount-amount.model';
import { CorrectionsMeasuresModel } from '@shared/models/corrections/corrections-form/measures/corrections-measures.model';
import { CorrectionsShipperModel } from '@shared/models/corrections/corrections-form/shipper/corrections-shipper.model';
import { CorrectionsShippingRemarksFormValuesModel } from '@shared/models/corrections/corrections-form/shipping-remarks/corrections-shipping-remarks.model';
import { CorrectionSicFormGroupModel } from '@shared/models/corrections/corrections-form/sic/corrections-sic.model';
import { CorrectionsTariffFormValuesModel } from '@shared/models/corrections/corrections-form/tariff/corrections-tariff.model';
import { CorrectionsWeightModel, CorrectionsWeightTypeModel } from '@shared/models/corrections/corrections-form/weight/corrections-weight.model';
import { CorrectionAccessorial, GetCorrectedShipmentResp, GetShipmentForPotentialCorrectionResp } from '@xpo-ltl-2.0/sdk-billcorrection';
import { ActionCd, Commodity, MiscLineItem } from '@xpo-ltl/sdk-common';

@Injectable()
export class CorrectionsCorrectedUpdateValuesService {

  constructor() { }

  // NOTE: Accessorials and Commodities are not here because they are list, when a list item is new there is not sequenceNbr to compare
  // so the corrected object has a variable to handle when should be mark as updated
  getCorrectedFormFields(correctedResponse: GetCorrectedShipmentResp, originalShipmentResponse: GetShipmentForPotentialCorrectionResp): CorrectionsFormValueModel {
    return {
      ...new CorrectionsFormValueModel(),
      accessorials: this.getAccessorials(correctedResponse, originalShipmentResponse),
      advanceScac: this.getAdvanceScac(correctedResponse),
      amcAmount: this.getAmcAmount(correctedResponse),
      beyondScac: this.getBeyondScac(correctedResponse),
      billto: this.getBillTo(correctedResponse),
      cancelCharges: this.getCancelCharges(correctedResponse),
      cashCollected: this.getCashCollectedConsignee(correctedResponse),
      cashPaid: this.getCashPaidByShipper(correctedResponse),
      chargeCode: this.getChargeCode(correctedResponse),
      codAmount: this.getCodAmount(correctedResponse) as CorrectionsCodAmountModel,
      commodities: this.getCommodities(correctedResponse, originalShipmentResponse),
      consignee: this.getConsignee(correctedResponse),
      customInBond: this.getCustomInBond(correctedResponse),
      discountAmount: this.getDiscountAmount(correctedResponse),
      measures: this.getMeasures(correctedResponse),
      sic: this.getSic(correctedResponse),
      shipper: this.getShipper(correctedResponse),
      shippingRemarks: this.getShippingRemarks(correctedResponse),
      tariff: this.getTariff(correctedResponse),
      weight: this.getWeightLine(correctedResponse)
    };
  }

  private getAccessorialValues(accessorial: CorrectionAccessorial, originalAccessorials: CorrectionAccessorial[], deleteInd?: boolean): CorrectionsAccessorialsModel {
    if (deleteInd) {
      return {
        ...new CorrectionsAccessorialsModel(),
        delete: deleteInd
      }
    }

    const originAcc = originalAccessorials?.find(acc => acc.sequenceNbr === accessorial.sequenceNbr);

    return {
      delete: undefined as any,
      sequenceNbr: accessorial?.sequenceNbr,
      code: accessorial?.accessorialCd !== originAcc?.accessorialCd ? accessorial.accessorialCd : undefined as any,
      description: accessorial?.accessorialDescription !== originAcc?.accessorialDescription ? accessorial.accessorialDescription : undefined as any,
      rate: accessorial?.tariffsRate !== originAcc?.tariffsRate ? accessorial?.tariffsRate : undefined as any,
      amount: accessorial?.chargeAmount?.amt !== originAcc?.chargeAmount?.amt ? accessorial?.chargeAmount?.amt : undefined as any,
      minimumChargeInd: undefined as any
    };
  }

  private getAccessorials(correctedResponse: GetCorrectedShipmentResp, originalShipmentResponse: GetShipmentForPotentialCorrectionResp): CorrectionsAccessorialsModel[] {
    const correctedAccessorials = correctedResponse?.correctedShipment?.accessorials;
    const originalAccessorials = originalShipmentResponse?.shipment?.accessorials;

    let accessorialsObj: { [key: string]: CorrectionsAccessorialsModel } = {};

    correctedAccessorials?.updatedAccessorials?.forEach(
      acc => {
        accessorialsObj[acc.accessorialCd] = this.getAccessorialValues(acc, originalAccessorials);
      }
    );

    correctedAccessorials?.removedAccessorials?.forEach(
      acc => {
        accessorialsObj[acc.accessorialCd] = this.getAccessorialValues(acc, originalAccessorials, true);
      }
    );

    const accessorials = Object.values(accessorialsObj);
    return accessorials;
  }

  private getAdvanceScac(correctedResponse: GetCorrectedShipmentResp): CorrectionsAdvanceScacModel {
    const deleteScac = correctedResponse?.correctedShipment?.deleteAdvanceCarrierInd;

    if (deleteScac) {
      return {
        deleteScac: true,
        scacCode: undefined,
        date: undefined,
        proNumber: undefined,
        advanceRevenue: undefined
      };
    }

    const correctedAdvanceCarrier = correctedResponse?.correctedShipment?.advanceCarrier;

    return {
      deleteScac: undefined,
      scacCode: correctedAdvanceCarrier?.carrierScacCd,
      date: correctedAdvanceCarrier?.carrierPickupDate,
      proNumber: correctedAdvanceCarrier?.carrierProNbr,
      advanceRevenue: correctedAdvanceCarrier?.chargeAmount
    };
  }

  private getAmcAmount(correctedResponse: GetCorrectedShipmentResp): CorrectionsAmcAmountModel {
    return {
      amount: correctedResponse?.amcAmount
    };
  }

  private getBeyondScac(correctedResponse: GetCorrectedShipmentResp): CorrectionsBeyondScacModel {
    const deleteScac = correctedResponse?.correctedShipment?.deleteBeyondCarrierInd;

    if (deleteScac) {
      return {
        deleteScac: true,
        scacCode: undefined,
        beyondRevenue: undefined
      };
    }

    const correctedBeyondCarrier = correctedResponse?.correctedShipment?.beyondCarrier;

    return {
      deleteScac: undefined,
      scacCode: correctedBeyondCarrier?.carrierScacCd,
      beyondRevenue: correctedBeyondCarrier?.chargeAmount
    };
  }

  private getBillTo(correctedResponse: GetCorrectedShipmentResp): CorrectionsBillToModel {

    if (correctedResponse.correctedShipment.deleteBillToInd) {
      return {
        deleteBillTo: true
      } as CorrectionsBillToModel;
    }

    if (!correctedResponse?.correctedShipment?.billTo) return new CorrectionsBillToModel();

    const correctedBillTo = correctedResponse.correctedShipment.billTo;

    return {
      customerNumber: correctedBillTo.cisCustNbr,
      madCode: correctedBillTo.asMatchedMadCd,
      nameOne: correctedBillTo.name1,
      nameTwo: correctedBillTo.name2,
      address: correctedBillTo.address,
      city: correctedBillTo.city,
      state: correctedBillTo.stateCd,
      zipCode: correctedBillTo.zip6,
      zipCodeComplementation: correctedBillTo.zip4RestUs,
      country: correctedBillTo.countryCd,
      chrgsResp: correctedBillTo.typeCd,
      deleteBillTo: undefined,
    };
  }

  private getCancelCharges(correctedResponse: GetCorrectedShipmentResp): CorrectionsCancelChargesModel {
    return {
      cancelChargesOpt: correctedResponse?.cancelChargesInd
    };
  }

  private getCashCollectedConsignee(correctedResponse: GetCorrectedShipmentResp): CorrectionsCashCollectedFormValuesModel {
    return {
      collectedInd: correctedResponse?.correctedShipment?.cashCollectedInd
    };
  }

  private getCashPaidByShipper(correctedResponse: GetCorrectedShipmentResp): CorrectionsCashPaidModel {
    const deleteInd = correctedResponse?.correctedShipment?.cashPrepaidLine?.listActionCd === ActionCd.DELETE || undefined;

    return {
      delete: deleteInd,
      amount: correctedResponse?.correctedShipment?.cashPrepaidLine?.amount,
      description: correctedResponse?.correctedShipment?.cashPrepaidLine?.description,
      sequenceNbr: correctedResponse?.correctedShipment?.cashPrepaidLine?.sequenceNbr,
    };
  }

  private getChargeCode(correctedResponse: GetCorrectedShipmentResp): CorrectionsChargeCodeModel {
    const chargeToCdCorrected = correctedResponse?.correctedShipment?.chargeToCd;
    const chargeCodeCollectCorrected = correctedResponse?.correctedShipment?.collectLine;
    const chargeCodePrepaidCorrected = correctedResponse?.correctedShipment?.prepaidLine;

    return {
      chargeCode: chargeToCdCorrected,
      colAmount: chargeCodeCollectCorrected?.amount,
      colText: chargeCodeCollectCorrected?.description,
      ppdAmount: chargeCodePrepaidCorrected?.amount,
      ppdText: chargeCodePrepaidCorrected?.description,
    };
  }

  private getCodAmount(correctedResponse: GetCorrectedShipmentResp): CorrectionsCodAmountModel {
    // TODO: Ramlal Endravath will add a field to know when the cod amount was deleted
    const correctedCodAmount = correctedResponse?.correctedShipment?.codAmountLine;
    if (!correctedCodAmount) return new CorrectionsCodAmountModel();

    return {
      amount: correctedCodAmount?.amount,
      delete: undefined,
      description: correctedCodAmount?.description,
      sequenceNbr: correctedCodAmount.sequenceNbr
    };
  }

  private getCommoditiesValues(correctedCommodity: Commodity, originalCommodities: Commodity[], deleteInd?: boolean): CorrectionCommoditiesModel {
    if (deleteInd) {
      return {
        ...new CorrectionCommoditiesModel(),
        delete: deleteInd
      };
    }

    const originalComm = originalCommodities?.find(commodity => commodity.sequenceNbr === correctedCommodity.sequenceNbr);

    return {
      delete: undefined as any,
      sequenceNbr: correctedCommodity?.sequenceNbr,
      amount: correctedCommodity?.amount !== originalComm?.amount ? correctedCommodity?.amount : undefined as any,
      class: correctedCommodity?.classType !== originalComm?.classType ? correctedCommodity?.classType : undefined as any,
      description: correctedCommodity?.description !== originalComm?.description ? correctedCommodity?.description : undefined as any,
      hazmat: correctedCommodity?.hazardousMtInd !== originalComm?.hazardousMtInd ? correctedCommodity?.hazardousMtInd : undefined as any,
      nmfc: correctedCommodity?.nmfcItemCd !== originalComm?.nmfcItemCd ? correctedCommodity?.nmfcItemCd : undefined as any,
      pieces: correctedCommodity?.piecesCount !== originalComm?.piecesCount ? correctedCommodity?.piecesCount : undefined as any,
      pkg: correctedCommodity?.packageCd !== originalComm?.packageCd ? correctedCommodity?.packageCd : undefined as any,
      weight: correctedCommodity?.weightLbs !== originalComm?.weightLbs ? correctedCommodity?.weightLbs : undefined as any,
      rate: correctedCommodity?.tariffsRate !== originalComm?.tariffsRate ? correctedCommodity?.tariffsRate : undefined as any,
    };
  }

  private getCommodities(correctedResponse: GetCorrectedShipmentResp, originalShipmentResponse: GetShipmentForPotentialCorrectionResp): CorrectionCommoditiesModel[] {
    const correctedCommodities = correctedResponse?.correctedShipment?.commodities;

    let commoditiesObj: { [key: string]: CorrectionCommoditiesModel } = {};
    const originalCommodities = originalShipmentResponse?.shipment?.commodities;

    correctedCommodities?.updatedCommodities?.forEach(
      commodity => {
        commoditiesObj[commodity.sequenceNbr] = this.getCommoditiesValues(commodity, originalCommodities);
      }
    );

    correctedCommodities?.deletedCommodities?.forEach(
      commodity => {
        commoditiesObj[commodity.sequenceNbr] = this.getCommoditiesValues(commodity, originalCommodities, true);
      }
    );

    const commodities = Object.values(commoditiesObj);
    return commodities;
  }

  private getConsignee(correctedResponse: GetCorrectedShipmentResp): CorrectionsConsigneeModel {
    if (!correctedResponse?.correctedShipment?.consignee) return new CorrectionsConsigneeModel();

    const correctedConsignee = correctedResponse.correctedShipment.consignee;

    return {
      customerNumber: correctedConsignee.cisCustNbr,
      madCode: correctedConsignee.asMatchedMadCd,
      nameOne: correctedConsignee.name1,
      nameTwo: correctedConsignee.name2,
      address: correctedConsignee.address,
      city: correctedConsignee.city,
      state: correctedConsignee.stateCd,
      zipCode: correctedConsignee.zip6,
      zipCodeComplementation: correctedConsignee.zip4RestUs,
      country: correctedConsignee.countryCd,
      creditStatus: correctedConsignee.creditStatusCd
    };
  }

  private getCustomInBond(correctedResponse: GetCorrectedShipmentResp): CorrectionsCustomInBondModel {
    const correctedCustomInBond = correctedResponse?.correctedShipment?.customsInBond;

    if (!correctedCustomInBond) return new CorrectionsCustomInBondModel();

    return {
      number: correctedCustomInBond?.bondNbr,
      city: correctedCustomInBond?.city,
      state: correctedCustomInBond?.state
    };
  }

  private getDiscountAmount(correctedResponse: GetCorrectedShipmentResp): CorrectionsDiscountAmountModel {
    const correctedDiscountAmount = correctedResponse?.correctedShipment?.discountAmountLine;
    if (!correctedDiscountAmount) return new CorrectionsDiscountAmountModel();

    return {
      delete: correctedDiscountAmount.listActionCd === ActionCd.DELETE || undefined as any,
      sequenceNbr: correctedDiscountAmount?.sequenceNbr,
      percentage: correctedResponse?.correctedShipment?.discountPercentage,
      description: correctedDiscountAmount?.description,
      amount: correctedDiscountAmount?.amount,
    };
  }

  private getMeasures(correctedResponse: GetCorrectedShipmentResp): CorrectionsMeasuresModel {
    const deleteMotorized: boolean = correctedResponse?.correctedShipment?.deleteMotorizedPiecesInd;
    const deletePalletCount: boolean = correctedResponse?.correctedShipment?.deleteTotalPalletCountInd;
    const deleteLinealFeet: boolean = correctedResponse?.correctedShipment?.deleteLinealFootTotalNbrInd;

    return {
      deleteMotorized: deleteMotorized || undefined,
      deletePalletCount: deletePalletCount || undefined,
      deleteLinealFeet: deleteLinealFeet || undefined,
      motorizedPcs: correctedResponse?.correctedShipment?.motorizedPiecesCount,
      palletCount: correctedResponse?.correctedShipment?.totalPalletCount,
      linealFeet: correctedResponse?.correctedShipment?.linealFootTotalNbr
    };
  }

  private getSic(correctedResponse: GetCorrectedShipmentResp): CorrectionSicFormGroupModel {
    const sicOrigin = correctedResponse?.correctedShipment?.originTerminalSic;
    const sicDestination = correctedResponse?.correctedShipment?.destinationTerminalSic;

    if (!sicOrigin && !sicDestination) return new CorrectionSicFormGroupModel();

    return {
      sicOrigin,
      sicDestination
    };
  }

  private getShipper(correctedResponse: GetCorrectedShipmentResp): CorrectionsShipperModel {
    if (!correctedResponse?.correctedShipment?.shipper) return new CorrectionsShipperModel();

    const correctedShipper = correctedResponse.correctedShipment.shipper;

    return {
      customerNumber: correctedShipper.cisCustNbr,
      madCode: correctedShipper.asMatchedMadCd,
      nameOne: correctedShipper.name1,
      nameTwo: correctedShipper.name2,
      address: correctedShipper.address,
      city: correctedShipper.city,
      state: correctedShipper.stateCd,
      zipCode: correctedShipper.zip6,
      zipCodeComplementation: correctedShipper.zip4RestUs,
      country: correctedShipper.countryCd,
      creditStatus: correctedShipper.creditStatusCd
    };
  }

  private getShippingRemarks(correctedResponse: GetCorrectedShipmentResp): CorrectionsShippingRemarksFormValuesModel {
    return {
      remarks: correctedResponse.correctedShipment.shipmentRemarks,
      deleteInd: correctedResponse?.correctedShipment?.deleteShipmentRemarksInd
    }
  }

  private getTariff(correctedResponse: GetCorrectedShipmentResp): CorrectionsTariffFormValuesModel {
    return {
      code: correctedResponse?.correctedShipment?.ratingTariffsId,
      effectiveDate: correctedResponse?.correctedShipment?.tariffEffectiveDate
    };
  }

  private getAsDeficitWeight(correctedWeight: MiscLineItem): CorrectionsWeightTypeModel | undefined {
    if (!correctedWeight) return;

    return {
      amount: correctedWeight.amount,
      description: correctedWeight.description,
      quantity: correctedWeight.quantity,
      minimumChargeInd: undefined as any,
      rate: correctedWeight.tariffsRate,
      sequenceNbr: correctedWeight.sequenceNbr,
      type: correctedWeight.lineTypeCd as any, // Note: it is any because the value here it doesn't matter, only need to have or not any value to highlight the field
    };
  }

  private getWeightType(correctedResponse: GetCorrectedShipmentResp): CorrectionsWeightFormTypeEnum | undefined {
    const correctedAsWeightValue = correctedResponse?.correctedShipment?.ratedAsWeightLine;
    const correctedDeficitWeightValue = correctedResponse?.correctedShipment?.deficitWeightLine;
    return correctedAsWeightValue ? CorrectionsWeightFormTypeEnum.asWeight : correctedDeficitWeightValue ? CorrectionsWeightFormTypeEnum.deficitWeight : undefined as any;
  }

  private getWeightLine(correctedResponse: GetCorrectedShipmentResp): CorrectionsWeightModel | undefined {
    const deleteWeight = correctedResponse?.correctedShipment?.deleteWeightAdjustmentsInd;
    if (deleteWeight) {
      return {
        deleteWeight: true,
        // Note: it is any because the value here it doesn't matter, only need to have or not any value to highlight the field
        asWeight: undefined as any,
        deficitWeight: undefined as any,
        optionSelected: undefined as any,
      };
    }

    const correctedAsWeightValue = correctedResponse?.correctedShipment?.ratedAsWeightLine;
    const correctedDeficitWeightValue = correctedResponse?.correctedShipment?.deficitWeightLine;

    if (!correctedAsWeightValue && !correctedDeficitWeightValue) return;

    return {
      deleteWeight: undefined as any,
      optionSelected: this.getWeightType(correctedResponse) as CorrectionsWeightFormTypeEnum,
      asWeight: this.getAsDeficitWeight(correctedAsWeightValue) as CorrectionsWeightTypeModel,
      deficitWeight: this.getAsDeficitWeight(correctedDeficitWeightValue) as CorrectionsWeightTypeModel,
    };
  }
}

import { Injectable } from '@angular/core';
import { CorrectionsPreviewRatesModel, CorrectionsPreviewRevenuesModel, CorrectionsPreviewTotalsModel } from '@shared/models/corrections/preview-rates/corrections-preview-rates.model';
import { CorrectionsDetailModel } from '@shared/models/corrections/corrections-detail.model';

@Injectable()
export class CorrectionsBillDetailsMetadataMapperService {

  getMappedDataForBillDetails(response: CorrectionsDetailModel | undefined ): CorrectionsPreviewRatesModel | undefined {
    const data = new CorrectionsPreviewRatesModel();
    if(!response) return undefined;
    data.pickupDate = response?.pickupDate;
    data.chargeCode = response.chargeCode;
    data.codAmount = response.codAmount;
    data.weightLine = response.weightLine;
    data.sic = response.sic;
    data.shipper = response.shipper;
    data.consignee = response.consignee;
    data.billTo = response.billTo;
    data.discounts = response.discountAmount;
    data.commodities = response.commodities;
    data.accesorials = response.accessorials;
    data.revenues = this.getRevenues(response);
    data.totals = this.getTotals(response);
    
    data.authorityRemarks = response.auditorRemarks?.authorityRemarks;
    data.customInBond = response.customInBond.bondNumber;
    data.cashPaid = response.cashPaidByShipper;
    data.shipmentRemarks = response.shippingRemarks.remarks;
    return data;
  }

  private getRevenues(response: CorrectionsDetailModel): CorrectionsPreviewRevenuesModel {
    return {
      totalRevenue: response.ctsRevenueAmount,
      advanceRevenue: response.advanceScac?.advanceRevenue,
      beyondRevenue: response.beyondScac?.beyondRevenue
    };
  }

  private getTotals(response: CorrectionsDetailModel): CorrectionsPreviewTotalsModel {
    return {
      totalPcs: response.commodities.reduce((acumulador, commodity) => acumulador + (commodity.pieces ?? 0), 0),
      totalWeight: response.totalRevenue.totalWeight,
      totalCharges: response.totalChargeAmount
    };
  }
}

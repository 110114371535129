import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CorrectionsComponent } from '@corrections/corrections.component';
import { AuditorFunctionGuard } from '@shared/guards/corrections/auditor-function/auditor-function.guard';
import { LoggedUserResolver } from '@shared/resolvers/user/logged-user.resolver';
import { CorrectionsRoutes } from '@shared/routes/corrections-routes.constant';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';

const routes: Routes = [
  {
    path: CorrectionsRoutes.main,
    component: CorrectionsComponent,
    canActivate: [XpoAuthenticationGuard],
    resolve: [LoggedUserResolver],
    children: [
      {
        path: CorrectionsRoutes.billDetail.main,
        loadChildren: () =>
          import('@corrections/bill/corrections-bill-details.module').then(
            (module) => module.CorrectionsBillDetailsModule
          ),
      },
      {
        path: CorrectionsRoutes.form.main,
        loadChildren: () =>
          import('@corrections/corrections-request/corrections-request.module').then(
            (module) => module.CorrectionsRequestModule
          ),
      },
      {
        path: CorrectionsRoutes.auditorFunction.main,
        loadChildren: () =>
          import('@corrections/corrections-auditor-function/corrections-auditor-function.module').then(
            (module) => module.CorrectionsAuditorFunctionModule
          ),
          canActivate: [AuditorFunctionGuard]
      },
      {
        path: '**',
        redirectTo: CorrectionsRoutes.billDetail.main,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '',
    redirectTo: CorrectionsRoutes.main,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Injectable } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from '../../enums/common-data/config-manager-properties.enum';

@Injectable({
  providedIn: 'root',
})
export class ApplicationUtilsService {
  buildVersion = this.configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
  appToDisplayAppSwitcher = this.configManagerService.getSetting<string>(ConfigManagerProperties.appSwitcherApps);

  // TODO Update these links once they are provided
  releaseNotesLink = '';
  helpLink = '';

  constructor(private configManagerService: ConfigManagerService) {}
}

import { Pipe, PipeTransform } from '@angular/core';
import { ConditioningService } from '@xpo-ltl/common-services';

@Pipe({
  name: 'proNumber',
})
export class ProNumberPipe implements PipeTransform {
  constructor(private conditioningService: ConditioningService) {}
  transform(proNumberInput: string): string {
    if (!Number(proNumberInput)) {
      return proNumberInput;
    }

    return this.conditioningService.conditionProNumber(proNumberInput, 10);
  }
}

import { createFeatureSelector, createReducer, on } from "@ngrx/store";
import { CorrectionsRequestFormTypeEnum } from "@shared/enums/corrections/corrections-form/corrections-form.enum";
import { CorrectionsBillChargesModel } from "@shared/models/corrections/bill-payment/corrections-bill-charges.model";
import { CorrectionsDetailModel } from "@shared/models/corrections/corrections-detail.model";
import { CorrectionsFormValueModel } from "@shared/models/corrections/corrections-form/corrections-form.model";
import { CorrectionsMetadataModel } from "@shared/models/corrections/corrections-list-metadata.model";
import { CorrectionsPreviewRatesModel } from "@shared/models/corrections/preview-rates/corrections-preview-rates.model";
import { ShipmentDetailRespModel } from '@shared/models/shipment/shipment-detail.model';
import { CorrectionsActions } from "../actions/corrections.actions";

const correctionsFeatureKey = 'correctionsKey';
const correctionsFeatureSelector = createFeatureSelector<CorrectionsStateModel>(correctionsFeatureKey);

export interface CorrectionsStateModel {
  proNbr: string;
  isCurrentRequestFormLocked: boolean;
  correctedFormFields: CorrectionsFormValueModel | undefined;
  currentRequestForm: CorrectionsFormValueModel | undefined;
  originalRequestForm: CorrectionsFormValueModel | undefined;
  correctionsBillCharges: CorrectionsBillChargesModel | undefined;
  correctionsDetail: CorrectionsDetailModel | undefined;
  correctionsCorrectedDetail: CorrectionsDetailModel | undefined;
  correctionsHistoryDetail: CorrectionsDetailModel | undefined;
  correctionsMetadata: CorrectionsMetadataModel | undefined;
  autoRatePreview: CorrectionsPreviewRatesModel | undefined;
  manualRatePreview: CorrectionsPreviewRatesModel | undefined;
  loadingGetBillCharges: boolean;
  ratingInformationDetail: ShipmentDetailRespModel | undefined;
  requestFormType: CorrectionsRequestFormTypeEnum | undefined;
  tdcOptionRequestFormDisabled: boolean;
  updateCorrectionRequestLoading: boolean;
}

const initialState: CorrectionsStateModel = {
  proNbr: '',
  isCurrentRequestFormLocked: false,
  correctedFormFields: undefined,
  currentRequestForm: undefined,
  originalRequestForm: undefined,
  correctionsBillCharges: undefined,
  correctionsDetail: undefined,
  correctionsCorrectedDetail: undefined,
  correctionsHistoryDetail: undefined,
  correctionsMetadata: undefined,
  manualRatePreview: undefined,
  autoRatePreview: undefined,
  loadingGetBillCharges: false,
  ratingInformationDetail: undefined,
  requestFormType: undefined,
  tdcOptionRequestFormDisabled: false,
  updateCorrectionRequestLoading: false,
};

const reducer = createReducer(
  initialState,
  on(CorrectionsActions.setProNbr, (state, { proNbr }): CorrectionsStateModel => {
    return {
      ...state,
      proNbr,
    };
  }),
  on(CorrectionsActions.setRequestFormType, (state, { requestFormType }): CorrectionsStateModel => {
    return {
      ...state,
      requestFormType,
    };
  }),
  on(CorrectionsActions.setCurrentRequestFormLockedInd, (state, { lockedInd }): CorrectionsStateModel => {
    return {
      ...state,
      isCurrentRequestFormLocked: lockedInd,
    };
  }),
  on(CorrectionsActions.setDisableTdcOptionRequestForm, (state, { optionDisabled }): CorrectionsStateModel => {
    return {
      ...state,
      tdcOptionRequestFormDisabled: optionDisabled,
    };
  }),
  on(CorrectionsActions.clearProNbr, (state): CorrectionsStateModel => {
    return {
      ...state,
      proNbr: '',
    };
  }),
  on(CorrectionsActions.getBillCharges, (state): CorrectionsStateModel => {
    return {
      ...state,
      correctionsBillCharges: undefined,
      loadingGetBillCharges: true,
    };
  }),
  on(CorrectionsActions.getBillChargesSuccess, (state, { billCharges }): CorrectionsStateModel => {
    return {
      ...state,
      correctionsBillCharges: billCharges,
      loadingGetBillCharges: false,
    };
  }),
  on(CorrectionsActions.getBillChargesFail, (state): CorrectionsStateModel => {
    return {
      ...state,
      loadingGetBillCharges: false,
    };
  }),
  on(CorrectionsActions.getDetailsSuccess, (state, { correctionsDetail }): CorrectionsStateModel => {
    return {
      ...state,
      correctionsDetail: correctionsDetail,
      correctionsCorrectedDetail: correctionsDetail,
    };
  }),
  on(CorrectionsActions.getHistoryDetails, (state): CorrectionsStateModel => {
    return {
      ...state,
      correctionsHistoryDetail: undefined,
      correctionsCorrectedDetail: undefined
    };
  }),
  on(CorrectionsActions.getHistoryDetailsSuccess, (state, { correctionsDetail }): CorrectionsStateModel => {
    return {
      ...state,
      correctionsHistoryDetail: correctionsDetail,
      correctionsCorrectedDetail: correctionsDetail
    };
  }),
  on(CorrectionsActions.getManualRatePreview, (state): CorrectionsStateModel => {
    return {
      ...state,
      manualRatePreview: undefined
    };
  }),
  on(CorrectionsActions.getManualRatePreviewSuccess, (state, { manualRatesPreview }): CorrectionsStateModel => {
    return {
      ...state,
      manualRatePreview: manualRatesPreview
    };
  }),
  on(CorrectionsActions.getAutoRatePreview, (state): CorrectionsStateModel => {
    return {
      ...state,
      autoRatePreview: undefined
    };
  }),
  on(CorrectionsActions.getAutoRatePreviewSuccess, (state, { autoRatesPreview }): CorrectionsStateModel => {
    return {
      ...state,
      autoRatePreview: autoRatesPreview
    };
  }),
  on(CorrectionsActions.getListCorrectionsMetaData, (state): CorrectionsStateModel => {
    return {
      ...state,
      correctionsMetadata: undefined,
    };
  }),
  on(CorrectionsActions.getRatingInformation, (state): CorrectionsStateModel => {
    return {
      ...state,
      ratingInformationDetail: undefined
    };
  }),
  on(CorrectionsActions.getRatingInformationSuccess, (state, { ratingInformationResp }): CorrectionsStateModel => {
    return {
      ...state,
      ratingInformationDetail: ratingInformationResp
    };
  }),
  on(
    CorrectionsActions.getListCorrectionsMetaDataSuccess,
    (state, { correctionsMetadata }): CorrectionsStateModel => {
      return {
        ...state,
        correctionsMetadata,
      };
    }
  ),
  on(
    CorrectionsActions.setOriginalRequestForm,
    (state, { originalRequestForm }): CorrectionsStateModel => {
      return {
        ...state,
        originalRequestForm,
      };
    }
  ),
  on(
    CorrectionsActions.setCurrentRequestForm,
    (state, { currentRequestForm }): CorrectionsStateModel => {
      return {
        ...state,
        currentRequestForm,
      };
    }
  ),
  on(
    CorrectionsActions.getPendingCorrectionDetailsSuccess,
    (state, { originalDetail, correctedDetail, correctedFormFields }): CorrectionsStateModel => {
      return {
        ...state,
        correctionsCorrectedDetail: correctedDetail,
        correctionsDetail: originalDetail,
        correctedFormFields
      };
    }
  ),
  on(
    CorrectionsActions.submitAuditorRequest,
    (state): CorrectionsStateModel => {
      return {
        ...state,
        updateCorrectionRequestLoading: true
      };
    }
  ),
  on(CorrectionsActions.submitAuditorRequestSuccess, CorrectionsActions.submitAuditorRequestFail, (state): CorrectionsStateModel => {
      return {
        ...state,
        updateCorrectionRequestLoading: false
      };
    }
  ),
);

export const CorrectionsReducer = {
  reducer,
  correctionsDetailFeatureKey: correctionsFeatureKey,
  correctionsDetailFeatureSelector: correctionsFeatureSelector,
  initialState,
};

import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { UserHandler } from '@app-store/user/handler/user.handler';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoggedUserResolver implements Resolve<boolean> {
  constructor(private userHandler: UserHandler) { }

  // NOTE: This resolver is necessary because the UI needs the logged user roles to apply some ram roles rules
  resolve(): Observable<boolean> {
    return this.userHandler.getLoggedUser$
      .pipe(
        tap(user => console.info('Info: is there logged user value?', !!user)),
        filter(user => !!user),
        take(1),
        map(() => true)
      );
  }
}

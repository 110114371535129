import { RateStatusCd } from "@xpo-ltl/sdk-common";
import {
  CorrectionsAccessorialRespModel,
  CorrectionsAdvanceScacRespModel,
  CorrectionsCashPaidByShipperRespModel,
  CorrectionsCommoditiesRespModel,
  CorrectionsCustomerRespModel,
  CorrectionsDiscountAmountRespModel,
  CorrectionsSicRespModel,
  CorrectionsBeyondScacRespModel,
  CorrectionsSupplementalReferencesRespModel,
  CorrectionsChargeCodeRespModel,
  CorrectionsCodAmountRespModel,
  CorrectionsWeightLineRespModel,
  CorrectionsPricingAgreementRespModel,
} from "../corrections-detail.model";

export class CorrectionsPreviewRatesModel {
  accesorials: CorrectionsAccessorialRespModel[];
  authorityRemarks: string;
  codAmount: CorrectionsCodAmountRespModel;
  pricingRulesetAfterChanges: CorrectionsPricingAgreementRespModel;
  billTo: CorrectionsCustomerRespModel;
  cashPaid: CorrectionsCashPaidByShipperRespModel;
  chargeCode: CorrectionsChargeCodeRespModel;
  commodities: CorrectionsCommoditiesRespModel[];
  consignee: CorrectionsCustomerRespModel;
  customInBond: string;
  discounts: CorrectionsDiscountAmountRespModel;
  pickupDate: string;
  rateStatusCd: RateStatusCd;
  revenues: CorrectionsPreviewRevenuesModel;
  shipmentRemarks: string;
  shipper: CorrectionsCustomerRespModel;
  sic: CorrectionsSicRespModel;
  totals: CorrectionsPreviewTotalsModel;
  weightLine: CorrectionsWeightLineRespModel;
}

export class CorrectionsPreviewRatesContentDetailsModel {
  route?: CorrectionsPreviewRatesRouteModel;
  supplementalReferences?: CorrectionsSupplementalReferencesRespModel;
  authorityRemarks?: string;
  shipmentRemarks?: string;
  customInBond?: string;
  billingRemarks?: string;
  cashPaid?: CorrectionsCashPaidByShipperRespModel;
  pricingRulesetBeforeChanges?: CorrectionsPricingAgreementRespModel;
  pricingRulesetAfterChanges?: CorrectionsPricingAgreementRespModel;
}

export class CorrectionsPreviewRatesRouteModel {
  advanceScac?: CorrectionsAdvanceScacRespModel;
  beyondScac?: CorrectionsBeyondScacRespModel;
}

export class CorrectionsPreviewRevenuesModel {
  totalRevenue: number;
  advanceRevenue: number;
  beyondRevenue: number;
}
export class CorrectionsPreviewTotalsModel {
  totalPcs: number;
  totalWeight: number;
  totalCharges: number;
  totalPercentage?: number;
}

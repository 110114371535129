import { Injectable } from '@angular/core';
import { CorrectionsHandler } from '@app-store/corrections/handler/corrections.handler';
import { UserHandler } from '@app-store/user/handler/user.handler';
import { RequestFormMarkedAction } from '@shared/enums/common-data/request-form.enum';
import { ScreenEnum } from '@shared/enums/common-data/screen.enum';
import { CorrectionsRequestFormTypeEnum } from '@shared/enums/corrections/corrections-form/corrections-form.enum';
import { CorrectionsDateTimeTypesEnum } from '@shared/enums/corrections/corrections-form/timeDeliveryWindow/corrections-time-delivery-window-form.enum';
import { CorrectionsWeightFormTypeEnum } from '@shared/enums/corrections/corrections-form/weight/corrections-weight-form.enum';
import { AccessorialsMapperService } from '@shared/mappers/common/accessorials/utls/accessorials-mapper.service';
import { CommoditiesMapperService } from '@shared/mappers/common/commodities/utils/commodities-mapper.service';
import { CommonDateMapperService } from '@shared/mappers/common/common-date/common-date-mapper.service';
import { TdcCommonMapperService } from '@shared/mappers/common/tdc/tdc-common-mapper.service';
import {
  CorrectionsAccessorialRespModel,
  CorrectionsAdvanceScacRespModel,
  CorrectionsAmcAmountRespModel,
  CorrectionsAsWeightRespModel,
  CorrectionsAuditorRemarksRespModel,
  CorrectionsBeyondScacRespModel,
  CorrectionsBilltoRespModel,
  CorrectionsCancelChargesRespModel,
  CorrectionsCashConsigneeRespModel,
  CorrectionsCashPaidByShipperRespModel,
  CorrectionsChargeCodeRespModel,
  CorrectionsCodAmountRespModel,
  CorrectionsCommoditiesRespModel,
  CorrectionsConsigneeRespModel,
  CorrectionsCustomInBondRespModel,
  CorrectionsDeficitWeightRespModel,
  CorrectionsDetailModel,
  CorrectionsDiscountAmountRespModel,
  CorrectionsExtraInformationModel,
  CorrectionsGuaranteedRespModel,
  CorrectionsInformationRequestModel,
  CorrectionsMeasuresRespModel,
  CorrectionsRequestInformationModel,
  CorrectionsShipperRespModel,
  CorrectionsShippingRemarksRespModel,
  CorrectionsSicRespModel,
  CorrectionsTariffRespModel,
  CorrectionsTimeDateCriticalRespModel,
  CorrectionsWeightLineRespModel
} from '@shared/models/corrections/corrections-detail.model';
import { CorrectionsMiscellaneousModel } from '@shared/models/corrections/corrections-form/miscellaneous/corrections-miscellaneous.model';
import { CorrectionsReasonModel } from '@shared/models/corrections/corrections-form/reason/corrections-reason.model';
import { CorrectionsListMetadataReasonDescriptionModel } from '@shared/models/corrections/corrections-list-metadata.model';
import { CorrectedShipmentRequest } from '@shared/models/shared/apis/correctected-shipment-request-api.model';
import { EmailModel } from '@shared/models/shared/email.model';
import {
  CorrectionAccessorial,
  CorrectionRequest,
  GetCorrectedShipmentResp,
  GetShipmentForPotentialCorrectionResp
} from '@xpo-ltl-2.0/sdk-billcorrection';
import { ActionCd, Commodity } from '@xpo-ltl/sdk-common';
import { take } from 'rxjs/operators';

@Injectable()
export class CorrectionsCorrectedRequestMapperService {

  serviceName = 'CorrectionsCorrectedRequestMapperService';

  constructor(
    private userHandler: UserHandler,
    private correctionsHandler: CorrectionsHandler,
    private commonDateMapperService: CommonDateMapperService,
    private tdcCommonMapperService: TdcCommonMapperService,
    private accessorialsMapperService: AccessorialsMapperService,
    private commoditiesMapperService: CommoditiesMapperService
  ) { }

  getRequest(correctionRequestInstId: number, currentScreen: ScreenEnum): CorrectedShipmentRequest {
    return {
      pathParams: {
        correctionRequestInstId: `${correctionRequestInstId}`
      },
      queryParams: {
        auditorFunctionInd: currentScreen === ScreenEnum.correctionsAuditRequestForm
      }
    };
  }

  getMappedData(correctedShipmentResponse: GetCorrectedShipmentResp, originalShipmentResponse: GetShipmentForPotentialCorrectionResp): CorrectionsDetailModel {
    if (correctedShipmentResponse?.correctedShipment?.timeDateCritical) {
      this.correctionsHandler.setRequestFormType(CorrectionsRequestFormTypeEnum.tdc);
    } else {
      this.correctionsHandler.setRequestFormType(CorrectionsRequestFormTypeEnum.default);
    }

    return {
      ...new CorrectionsDetailModel(),
      accessorials: this.getAccessorials(correctedShipmentResponse, originalShipmentResponse),
      advanceScac: this.getAdvanceScac(correctedShipmentResponse, originalShipmentResponse),
      amcAmount: this.getAmcAmount(correctedShipmentResponse, originalShipmentResponse),
      auditorComments: this.getAuditorComments(correctedShipmentResponse),
      auditorRemarks: this.getAuditorRemarks(correctedShipmentResponse),
      beyondScac: this.getBeyondScac(correctedShipmentResponse, originalShipmentResponse),
      billTo: this.getBillTo(correctedShipmentResponse, originalShipmentResponse),
      cancelCharges: this.getCancelCharges(correctedShipmentResponse),
      cashCollectedConsignee: this.getCashCollectedConsignee(correctedShipmentResponse, originalShipmentResponse),
      cashPaidByShipper: this.getCashPaidByShipper(correctedShipmentResponse, originalShipmentResponse),
      chargeCode: this.getChargeCode(correctedShipmentResponse, originalShipmentResponse),
      codAmount: this.getCodAmount(correctedShipmentResponse, originalShipmentResponse),
      commodities: this.getCommodities(correctedShipmentResponse, originalShipmentResponse),
      consignee: this.getConsignee(correctedShipmentResponse, originalShipmentResponse),
      correctionRequestInstId: this.getCorrectionRequestInstId(correctedShipmentResponse),
      customInBond: this.getCustomInBond(correctedShipmentResponse, originalShipmentResponse),
      discountAmount: this.getDiscountAmount(correctedShipmentResponse, originalShipmentResponse),
      guaranteed: this.getGuaranteed(correctedShipmentResponse, originalShipmentResponse),
      informationRequestInd: this.getInformationRequest(correctedShipmentResponse),
      measures: this.getMeasures(correctedShipmentResponse, originalShipmentResponse),
      miscellaneous: this.getMiscellaneous(correctedShipmentResponse),
      pickupDate: this.getPickupDate(correctedShipmentResponse),
      proNbr: this.getProNbr(correctedShipmentResponse),
      reason: this.getReason(correctedShipmentResponse),
      requestInformation: this.getRequestInformation(correctedShipmentResponse, originalShipmentResponse),
      sic: this.getSic(correctedShipmentResponse, originalShipmentResponse),
      shipmentInstId: this.getShipmentInstId(correctedShipmentResponse),
      shipper: this.getShipper(correctedShipmentResponse, originalShipmentResponse),
      shippingRemarks: this.getShippingRemarks(correctedShipmentResponse, originalShipmentResponse),
      tariff: this.getTariff(correctedShipmentResponse, originalShipmentResponse),
      timeDateCritical: this.getTimeDateCritical(correctedShipmentResponse, originalShipmentResponse),
      weightLine: this.getWeightLine(correctedShipmentResponse, originalShipmentResponse)
    };
  }

  setLockedInd(correctedResponse: GetCorrectedShipmentResp): void {
    this.correctionsHandler.setCurrentRequestFormLockedInd(!!correctedResponse?.lockedInd);
  }

  private getAccessorials(correctedResponse: GetCorrectedShipmentResp, originalResponse: GetShipmentForPotentialCorrectionResp): CorrectionsAccessorialRespModel[] {
    const correctedAccessorials = correctedResponse?.correctedShipment?.accessorials;
    const originalAccessorials = originalResponse?.shipment?.accessorials;

    let accessorialsObj: { [key: string]: CorrectionsAccessorialRespModel } = {};
    let originalAccessorialsObj: { [key: string]: Object } = {};

    /**
     * NOTE: Original Accessorials should be mapped first because
     * The UI only maps updated accessorials that exist in the original data, because if an item is deleted due to another request was approved, the UI should not display it.
    */
    originalAccessorials?.filter(acc => !accessorialsObj[acc.accessorialCd]).forEach(
      acc => {
        const id = this.accessorialsMapperService.getId(acc);
        originalAccessorialsObj[id] = {};
        accessorialsObj[id] = this.getAccessorialValues(acc, false, id);
      }
    );

    correctedAccessorials?.updatedAccessorials?.forEach(
      acc => {
        // NOTE: The UI only maps updated accessorials that exist in the original data, because if an item is deleted due to another request was approved, the UI should not display it.
        const id = this.accessorialsMapperService.getId(acc);
        if (originalAccessorialsObj[id]) {
          accessorialsObj[id] = this.getAccessorialValues(acc, false, id, RequestFormMarkedAction.update);
        }
      }
    );

    correctedAccessorials?.removedAccessorials?.forEach(
      acc => {
        // NOTE: The UI only maps delete accessorials that exist in the original data, because if an item is deleted due to another request was approved, the UI should not display it.
        const id = this.accessorialsMapperService.getId(acc);
        if (originalAccessorialsObj[id]) {
          accessorialsObj[id] = this.getAccessorialValues(acc, true, id, RequestFormMarkedAction.delete);
        }
      }
    );

    correctedAccessorials?.addedAccessorials?.forEach(
      (acc, index) => {
        const id = this.accessorialsMapperService.getIdForNewItem(index);
        accessorialsObj[id] = this.getAccessorialValues(acc, false, id, RequestFormMarkedAction.add);
      }
    );

    const accessorials = Object.values(accessorialsObj);
    return accessorials;
  }

  private getAccessorialValues(accessorial: CorrectionAccessorial, deleteInd: boolean, id: string, markedAction?: RequestFormMarkedAction): CorrectionsAccessorialRespModel {
    return {
      id,
      delete: deleteInd,
      sequenceNbr: accessorial.sequenceNbr,
      code: accessorial.accessorialCd,
      description: accessorial.accessorialDescription,
      rate: accessorial.tariffsRate,
      amount: accessorial.chargeAmount?.amt,
      minimumChargeInd: accessorial.minChargeInd,
      markedAction,
    };
  }

  private getAdvanceScac(correctedResponse: GetCorrectedShipmentResp, originalResponse: GetShipmentForPotentialCorrectionResp): CorrectionsAdvanceScacRespModel {
    const correctedAdvanceCarrier = correctedResponse?.correctedShipment?.advanceCarrier;
    const originalAdvanceCarrier = originalResponse?.shipment?.advanceCarrier;

    return {
      deleteAdvanceScac: correctedResponse?.correctedShipment?.deleteAdvanceCarrierInd,
      scacCode: correctedAdvanceCarrier?.carrierScacCd != null ? correctedAdvanceCarrier.carrierScacCd : originalAdvanceCarrier?.carrierScacCd,
      date: correctedAdvanceCarrier?.carrierPickupDate != null ? correctedAdvanceCarrier.carrierPickupDate : originalAdvanceCarrier?.carrierPickupDate,
      proNumber: correctedAdvanceCarrier?.carrierProNbr != null ? correctedAdvanceCarrier.carrierProNbr : originalAdvanceCarrier?.carrierProNbr,
      advanceRevenue: correctedAdvanceCarrier?.chargeAmount != null ? correctedAdvanceCarrier.chargeAmount : originalAdvanceCarrier?.chargeAmount
    };
  }

  private getAmcAmount(correctedResponse: GetCorrectedShipmentResp, originalResponse: GetShipmentForPotentialCorrectionResp): CorrectionsAmcAmountRespModel {
    return {
      amount: correctedResponse?.amcAmount != null ? correctedResponse?.amcAmount : originalResponse?.amcAmount
    };
  }

  private getAuditorComments(correctedResponse: GetCorrectedShipmentResp): CorrectionsExtraInformationModel {
    return {
      auditorComments: correctedResponse?.correctionRequest?.auditorComment
    };
  }

  private getAuditorRemarks(correctedResponse: GetCorrectedShipmentResp): CorrectionsAuditorRemarksRespModel {
    return {
      authorityRemarks: correctedResponse?.correctionRequest?.authorization
    };
  }

  private getBeyondScac(correctedResponse: GetCorrectedShipmentResp, originalResponse: GetShipmentForPotentialCorrectionResp): CorrectionsBeyondScacRespModel {
    const correctedBeyondCarrier = correctedResponse?.correctedShipment?.beyondCarrier;
    const originalBeyondCarrier = originalResponse?.shipment?.beyondCarrier;

    return {
      deleteBeyondScac: correctedResponse?.correctedShipment?.deleteBeyondCarrierInd,
      scacCode: correctedBeyondCarrier?.carrierScacCd ?? originalBeyondCarrier?.carrierScacCd,
      fromTerminalSic: correctedBeyondCarrier?.fromTerminalSicCd ?? originalBeyondCarrier?.fromTerminalSicCd,
      beyondRevenue: correctedBeyondCarrier?.chargeAmount ?? originalBeyondCarrier?.chargeAmount
    };
  }

  private getBillTo(correctedResponse: GetCorrectedShipmentResp, originalResponse: GetShipmentForPotentialCorrectionResp): CorrectionsBilltoRespModel {
    const correctedBillTo = correctedResponse?.correctedShipment?.billTo;
    const originalBillTo = originalResponse?.shipment?.billTo;

    return {
      customerNumber: correctedBillTo?.cisCustNbr != null ? correctedBillTo.cisCustNbr : originalBillTo?.cisCustNbr,
      madCode: correctedBillTo?.asMatchedMadCd != null ? correctedBillTo.asMatchedMadCd : originalBillTo?.asMatchedMadCd,
      name1: correctedBillTo?.name1 != null ? correctedBillTo.name1 : originalBillTo?.name1,
      name2: correctedBillTo?.name2 != null ? correctedBillTo.name2 : originalBillTo?.name2,
      address: correctedBillTo?.address != null ? correctedBillTo.address : originalBillTo?.address,
      city: correctedBillTo?.city != null ? correctedBillTo.city : originalBillTo?.city,
      state: correctedBillTo?.stateCd != null ? correctedBillTo.stateCd : originalBillTo?.stateCd,
      zip: correctedBillTo?.zip6 != null ? correctedBillTo.zip6 : originalBillTo?.zip6,
      zipRest: correctedBillTo?.zip4RestUs != null ? correctedBillTo.zip4RestUs : originalBillTo?.zip4RestUs,
      country: correctedBillTo?.countryCd != null ? correctedBillTo.countryCd : originalBillTo?.countryCd,
      chrgsResp: correctedBillTo?.typeCd != null ? correctedBillTo.typeCd : originalBillTo?.typeCd,
      delete: correctedResponse?.correctedShipment?.deleteBillToInd
    };
  }

  private getCancelCharges(correctedResponse: GetCorrectedShipmentResp): CorrectionsCancelChargesRespModel {
    return {
      cancelChargesInd: correctedResponse?.cancelChargesInd
    };
  }

  private getCashCollectedConsignee(correctedResponse: GetCorrectedShipmentResp, originalResponse: GetShipmentForPotentialCorrectionResp): CorrectionsCashConsigneeRespModel {
    return {
      collectedInd: correctedResponse?.correctedShipment?.cashCollectedInd != null ? correctedResponse.correctedShipment.cashCollectedInd : originalResponse?.shipment?.cashCollectedInd
    };
  }

  private getCashPaidByShipper(correctedResponse: GetCorrectedShipmentResp, originalResponse: GetShipmentForPotentialCorrectionResp): CorrectionsCashPaidByShipperRespModel {
    const correctedCashPrepaidLine = correctedResponse?.correctedShipment?.cashPrepaidLine;
    const originalCashPrepaidLine = originalResponse?.shipment?.cashPrepaidLine;

    return {
      deleteInd: correctedCashPrepaidLine?.listActionCd === ActionCd.DELETE,
      amount: correctedCashPrepaidLine?.amount != null ? correctedCashPrepaidLine.amount : originalCashPrepaidLine?.amount,
      description: correctedCashPrepaidLine?.description != null ? correctedCashPrepaidLine.description : originalCashPrepaidLine?.description,
      sequenceNbr: correctedCashPrepaidLine?.sequenceNbr != null ? correctedCashPrepaidLine.sequenceNbr : originalCashPrepaidLine?.sequenceNbr,
    };
  }

  private getChargeCode(correctedResponse: GetCorrectedShipmentResp, originalResponse: GetShipmentForPotentialCorrectionResp): CorrectionsChargeCodeRespModel {
    const chargeToCdCorrected = correctedResponse?.correctedShipment?.chargeToCd;
    const chargeToCdOriginal = originalResponse?.shipment?.chargeToCd;

    const chargeCodeCollectCorrected = correctedResponse?.correctedShipment?.collectLine;
    const chargeCodeCollectOriginal = originalResponse?.shipment?.collectLine;

    const chargeCodePrepaidCorrected = correctedResponse?.correctedShipment?.prepaidLine;
    const chargeCodePrepaidOriginal = originalResponse?.shipment?.prepaidLine;

    return {
      chargeCode: chargeToCdCorrected != null ? chargeToCdCorrected : chargeToCdOriginal,
      colSequenceNbr: chargeCodeCollectCorrected?.sequenceNbr != null ? chargeCodeCollectCorrected.sequenceNbr : chargeCodeCollectOriginal?.sequenceNbr,
      colAmount: chargeCodeCollectCorrected?.amount != null ? chargeCodeCollectCorrected.amount : chargeCodeCollectOriginal?.amount,
      colText: chargeCodeCollectCorrected?.description != null ? chargeCodeCollectCorrected.description : chargeCodeCollectOriginal?.description,
      ppdSequenceNbr: chargeCodePrepaidCorrected?.sequenceNbr != null ? chargeCodePrepaidCorrected.sequenceNbr : chargeCodePrepaidOriginal?.sequenceNbr,
      ppdAmount: chargeCodePrepaidCorrected?.amount != null ? chargeCodePrepaidCorrected.amount : chargeCodePrepaidOriginal?.amount,
      ppdText: chargeCodePrepaidCorrected?.description != null ? chargeCodePrepaidCorrected.description : chargeCodePrepaidOriginal?.description,
    };
  }

  private getCodAmount(correctedResponse: GetCorrectedShipmentResp, originalResponse: GetShipmentForPotentialCorrectionResp): CorrectionsCodAmountRespModel {
    // TODO: Ramlal Endravath will add a field to know when the cod amount was deleted
    const correctedCodAmount = correctedResponse?.correctedShipment?.codAmountLine;
    const originalCodAmount = originalResponse?.shipment?.codAmountLine;

    return {
      amount: correctedCodAmount?.amount != null ? correctedCodAmount.amount : originalCodAmount?.amount,
      description: correctedCodAmount?.description != null ? correctedCodAmount.description : originalCodAmount?.description,
      sequenceNbr: correctedCodAmount?.sequenceNbr != null ? correctedCodAmount.sequenceNbr : originalCodAmount?.sequenceNbr,
    };
  }

  private getCommoditiesValues(commodity: Commodity, deleteInd: boolean, id: string, markedAction?: RequestFormMarkedAction): CorrectionsCommoditiesRespModel {
    return {
      id,
      delete: deleteInd,
      sequenceNbr: commodity?.sequenceNbr,
      amount: commodity?.amount,
      class: commodity?.classType,
      description: commodity?.description,
      hazmat: commodity?.hazardousMtInd,
      nmfc: commodity?.nmfcItemCd,
      pieces: commodity?.piecesCount,
      pkg: commodity?.packageCd,
      weight: commodity?.weightLbs,
      rate: commodity?.tariffsRate,
      markedAction,
      minimumChargeInd: commodity?.minimumChargeInd
    };
  }

  private getCommodities(correctedResponse: GetCorrectedShipmentResp, originalResponse: GetShipmentForPotentialCorrectionResp): CorrectionsCommoditiesRespModel[] {
    const correctedCommodities = correctedResponse?.correctedShipment?.commodities;
    const originalCommodities = originalResponse?.shipment?.commodities;

    let commoditiesObj: { [key: string]: CorrectionsCommoditiesRespModel } = {};
    let originalCommoditiesObj: { [key: string]: Object } = {};

    /**
     * NOTE: Original commodities should be mapped first because
     * The UI only maps updated commodities that exist in the original data, because if an item is deleted due to another request was approved, the UI should not display it.
    */
    originalCommodities?.filter(commodity => !commoditiesObj[commodity.sequenceNbr])
      .forEach(
        commodity => {
          const id = this.commoditiesMapperService.getId(commodity);
          originalCommoditiesObj[id] = {};
          commoditiesObj[id] = this.getCommoditiesValues(commodity, false, id);
        }
      );

    correctedCommodities?.updatedCommodities?.forEach(
      commodity => {
        // NOTE: The UI only maps updated commodities that exist in the original data, because if an item is deleted due to another request was approved, the UI should not display it.
        const id = this.commoditiesMapperService.getId(commodity);
        if (originalCommoditiesObj[id]) {
          commoditiesObj[id] = this.getCommoditiesValues(commodity, false, id, RequestFormMarkedAction.update);
        }
      }
    );

    correctedCommodities?.deletedCommodities?.forEach(
      commodity => {
        // NOTE: The UI only maps delete commodities that exist in the original data, because if an item is deleted due to another request was approved, the UI should not display it.
        const id = this.commoditiesMapperService.getId(commodity);
        if (originalCommoditiesObj[id]) {
          commoditiesObj[id] = this.getCommoditiesValues(commodity, true, id, RequestFormMarkedAction.delete);
        }
      }
    );

    correctedCommodities?.addedCommodities?.forEach(
      (commodity, index) => {
        const commodityNewId = this.commoditiesMapperService.getIdForNewItem(index);
        commoditiesObj[commodityNewId] = this.getCommoditiesValues(commodity, false, commodityNewId, RequestFormMarkedAction.add);
      }
    );

    const commodities = Object.values(commoditiesObj);
    return commodities;
  }

  private getConsignee(correctedResponse: GetCorrectedShipmentResp, originalResponse: GetShipmentForPotentialCorrectionResp): CorrectionsConsigneeRespModel {
    const correctedConsignee = correctedResponse?.correctedShipment?.consignee;
    const originalConsignee = originalResponse?.shipment?.consignee;

    return {
      customerNumber: correctedConsignee?.cisCustNbr != null ? correctedConsignee.cisCustNbr : originalConsignee?.cisCustNbr,
      madCode: correctedConsignee?.asMatchedMadCd != null ? correctedConsignee.asMatchedMadCd : originalConsignee?.asMatchedMadCd,
      name1: correctedConsignee?.name1 != null ? correctedConsignee.name1 : originalConsignee?.name1,
      name2: correctedConsignee?.name2 != null ? correctedConsignee.name2 : originalConsignee?.name2,
      address: correctedConsignee?.address != null ? correctedConsignee.address : originalConsignee?.address,
      city: correctedConsignee?.city != null ? correctedConsignee.city : originalConsignee?.city,
      state: correctedConsignee?.stateCd != null ? correctedConsignee.stateCd : originalConsignee?.stateCd,
      zip: correctedConsignee?.zip6 != null ? correctedConsignee.zip6 : originalConsignee?.zip6,
      zipRest: correctedConsignee?.zip4RestUs != null ? correctedConsignee.zip4RestUs : originalConsignee?.zip4RestUs,
      country: correctedConsignee?.countryCd != null ? correctedConsignee.countryCd : originalConsignee?.countryCd,
      creditStatus: correctedConsignee?.creditStatusCd != null ? correctedConsignee.creditStatusCd : originalConsignee?.creditStatusCd
    };
  }

  private getCorrectionRequestInstId(correctedResponse: GetCorrectedShipmentResp): number | undefined {
    return correctedResponse?.correctionRequest?.correctionRequestInstId;
  }

  private getCustomInBond(correctedResponse: GetCorrectedShipmentResp, originalResponse: GetShipmentForPotentialCorrectionResp): CorrectionsCustomInBondRespModel {
    const correctedCustomInBond = correctedResponse?.correctedShipment?.customsInBond;
    const originalCustomInBond = originalResponse?.shipment?.customsInBond;

    return {
      bondNumber: correctedCustomInBond?.bondNbr != null ? correctedCustomInBond.bondNbr : originalCustomInBond?.bondNbr,
      city: correctedCustomInBond?.city != null ? correctedCustomInBond.city : originalCustomInBond?.city,
      state: correctedCustomInBond?.state != null ? correctedCustomInBond.state : originalCustomInBond?.state
    };
  }

  private getDiscountAmount(correctedResponse: GetCorrectedShipmentResp, originalResponse: GetShipmentForPotentialCorrectionResp): CorrectionsDiscountAmountRespModel {
    const correctedDiscountAmount = correctedResponse?.correctedShipment?.discountAmountLine;
    const originalDiscountAmount = originalResponse?.shipment?.discountAmountLine;

    return {
      deleteInd: correctedDiscountAmount?.listActionCd === ActionCd.DELETE,
      sequenceNbr: correctedDiscountAmount?.sequenceNbr != null ? correctedDiscountAmount.sequenceNbr : originalDiscountAmount?.sequenceNbr,
      percentage: correctedResponse?.correctedShipment?.discountPercentage != null ? correctedResponse.correctedShipment.discountPercentage : originalResponse?.shipment?.discountPercentage,
      description: correctedDiscountAmount?.description != null ? correctedDiscountAmount.description : originalDiscountAmount?.description,
      amount: correctedDiscountAmount?.amount != null ? correctedDiscountAmount.amount : originalDiscountAmount?.amount
    };
  }

  private getEmails(correctedResponse: GetCorrectedShipmentResp): EmailModel[] {
    const emailAddresses: string[] = correctedResponse?.emailAddresses?.map(email => email.emailAddress) || [];
    if (emailAddresses.length === 0) return [];

    let loggedUserEmail: string | undefined;
    this.userHandler.getLoggedUser$.pipe(take(1)).subscribe(
      loggedUser => loggedUserEmail = loggedUser?.email
    );

    return emailAddresses.map(email => {
      return email === loggedUserEmail
        ? { emailAddress: email, isLoggedUserEmail: true, sendCopyToMyEmail: true } as EmailModel
        : { emailAddress: email }
    });
  }

  private getGuaranteed(correctedResponse: GetCorrectedShipmentResp, originalResponse: GetShipmentForPotentialCorrectionResp): CorrectionsGuaranteedRespModel {
    return {
      guaranteedInd: correctedResponse?.correctedShipment?.guaranteedInd != null ? correctedResponse?.correctedShipment?.guaranteedInd : originalResponse?.shipment?.guaranteedInd
    };
  }

  private getInformationRequest(correctedResponse: GetCorrectedShipmentResp): CorrectionsInformationRequestModel {
    return {
      informationRequestInd: correctedResponse?.correctionRequest?.informationRequestInd
    };
  }

  private getMeasures(correctedResponse: GetCorrectedShipmentResp, originalResponse: GetShipmentForPotentialCorrectionResp): CorrectionsMeasuresRespModel {

    return {
      deleteMotorizedPieces: correctedResponse?.correctedShipment?.deleteMotorizedPiecesInd,
      deleteTotalPalletCount: correctedResponse?.correctedShipment?.deleteTotalPalletCountInd,
      deleteLinealFeet: correctedResponse?.correctedShipment?.deleteLinealFootTotalNbrInd,
      motorizedPieces: correctedResponse?.correctedShipment?.motorizedPiecesCount ? correctedResponse.correctedShipment.motorizedPiecesCount : originalResponse?.shipment?.motorizedPiecesCount,
      palletCount: correctedResponse?.correctedShipment?.totalPalletCount ? correctedResponse.correctedShipment.totalPalletCount : originalResponse?.shipment?.totalPalletCount,
      linealFeet: correctedResponse?.correctedShipment?.linealFootTotalNbr ? correctedResponse.correctedShipment.linealFootTotalNbr : originalResponse?.shipment?.linealFootTotalNbr
    };
  }

  private getMiscellaneous(correctedResponse: GetCorrectedShipmentResp): CorrectionsMiscellaneousModel {
    return {
      type: correctedResponse?.rateTypeCd,
      instructions: correctedResponse?.ratingInstructions
    };
  }

  private getPickupDate(correctedResponse: GetCorrectedShipmentResp): string {
    return correctedResponse?.correctionRequest?.pickupDate;
  }

  private getProNbr(correctedResponse: GetCorrectedShipmentResp): string {
    return correctedResponse?.correctionRequest?.proNbr;
  }

  private getRequestInfoCategoryReason(correctedResponse: GetCorrectedShipmentResp): string | undefined {
    if (!correctedResponse?.correctionRequest?.reason && !correctedResponse.categoryDescription) return undefined;
    const category = correctedResponse.categoryDescription || '';
    const reason = correctedResponse.correctionRequest.reason?.reasonDescription || '';
    const separator = category && reason ? '-' : '';
    return `${category} ${separator} ${reason}`.trim();
  }

  private getRequestInformation(correctedResponse: GetCorrectedShipmentResp, originalResponse: GetShipmentForPotentialCorrectionResp): CorrectionsRequestInformationModel {
    const correctionRequest = correctedResponse.correctionRequest;
    const auditInfo = correctionRequest?.auditInfo;
    const shipment = originalResponse.shipment;

    if (!correctionRequest) console.warn('There is not correction Request data', 'Method: getRequestInformation', this.serviceName);
    if (!auditInfo) console.warn('There is not auditInfo data', 'Method: getRequestInformation', this.serviceName);
    if (!shipment) console.warn('There is not shipment data', 'Method: getRequestInformation', this.serviceName);

    const deliveryDate = this.getDateTime(shipment?.lastMovementDateTime, 'lastMovementDateTime', 'getRequestInformation');
    const requestDateTime = this.getDateTime(auditInfo?.createdTimestamp, 'createdTimestamp', 'getRequestInformation');
    const ccEmailTo = this.getEmails(correctedResponse);

    return {
      categoryReason: this.getRequestInfoCategoryReason(correctedResponse),
      ccEmailTo,
      correctionQueue: correctionRequest?.reviewQueue,
      deliveryDate,
      expectedDelivery: shipment?.estimatedDeliveryDate,
      isDriverWaiting: correctionRequest?.priorityInd,
      pickupDate: correctionRequest?.pickupDate,
      requesterComments: correctionRequest?.requesterComment,
      requestDateTime,
      requester: correctionRequest?.requesterName,
      requesterUserId: auditInfo?.createdById
    };
  }

  private getSic(correctedResponse: GetCorrectedShipmentResp, originalResponse: GetShipmentForPotentialCorrectionResp): CorrectionsSicRespModel {
    return {
      origin: correctedResponse?.correctedShipment?.originTerminalSic != null ? correctedResponse.correctedShipment.originTerminalSic : originalResponse?.shipment?.originTerminalSic,
      destination: correctedResponse?.correctedShipment?.destinationTerminalSic != null ? correctedResponse.correctedShipment.destinationTerminalSic : originalResponse?.shipment?.destinationTerminalSic,
    };
  }

  private getShipmentInstId(correctedResponse: GetCorrectedShipmentResp): number {
    return correctedResponse?.correctionRequest?.shipmentInstId;
  }

  private getShipper(correctedResponse: GetCorrectedShipmentResp, originalResponse: GetShipmentForPotentialCorrectionResp): CorrectionsShipperRespModel {
    const correctedShipper = correctedResponse?.correctedShipment?.shipper;
    const originalShipper = originalResponse?.shipment?.shipper;

    return {
      customerNumber: correctedShipper?.cisCustNbr != null ? correctedShipper.cisCustNbr : originalShipper?.cisCustNbr,
      madCode: correctedShipper?.asMatchedMadCd != null ? correctedShipper.asMatchedMadCd : originalShipper?.asMatchedMadCd,
      name1: correctedShipper?.name1 != null ? correctedShipper.name1 : originalShipper?.name1,
      name2: correctedShipper?.name2 != null ? correctedShipper.name2 : originalShipper?.name2,
      address: correctedShipper?.address != null ? correctedShipper.address : originalShipper?.address,
      city: correctedShipper?.city != null ? correctedShipper.city : originalShipper?.city,
      state: correctedShipper?.stateCd != null ? correctedShipper.stateCd : originalShipper?.stateCd,
      zip: correctedShipper?.zip6 != null ? correctedShipper.zip6 : originalShipper?.zip6,
      zipRest: correctedShipper?.zip4RestUs != null ? correctedShipper.zip4RestUs : originalShipper?.zip4RestUs,
      country: correctedShipper?.countryCd != null ? correctedShipper.countryCd : originalShipper?.countryCd,
      creditStatus: correctedShipper?.creditStatusCd != null ? correctedShipper.creditStatusCd : originalShipper?.creditStatusCd
    };
  }

  private getShippingRemarks(correctedResponse: GetCorrectedShipmentResp, originalResponse: GetShipmentForPotentialCorrectionResp): CorrectionsShippingRemarksRespModel {
    const correctedRemarksValue = correctedResponse?.correctedShipment?.shipmentRemarks;
    const originalRemarksValue = originalResponse?.shipment?.shipmentRemarks;

    return {
      deleteInd: correctedResponse?.correctedShipment?.deleteShipmentRemarksInd,
      remarks: correctedRemarksValue != null ? correctedRemarksValue : originalRemarksValue
    };
  }

  private getTariff(correctedResponse: GetCorrectedShipmentResp, originalResponse: GetShipmentForPotentialCorrectionResp): CorrectionsTariffRespModel {
    return {
      code: correctedResponse?.correctedShipment?.ratingTariffsId != null ? correctedResponse.correctedShipment.ratingTariffsId : originalResponse?.shipment?.ratingTariffsId,
      effectiveDate: correctedResponse?.correctedShipment?.tariffEffectiveDate != null ? correctedResponse.correctedShipment.tariffEffectiveDate : originalResponse?.shipment?.tariffEffectiveDate,
    };
  }

  private getTimeDateCritical(correctedResponse: GetCorrectedShipmentResp, originalResponse: GetShipmentForPotentialCorrectionResp): CorrectionsTimeDateCriticalRespModel {
    const correctedTimeDateCritical = correctedResponse?.correctedShipment?.timeDateCritical;
    const originalTimeDateCritical = originalResponse?.shipment?.timeDateCritical;

    return {
      shipmentInstId: correctedTimeDateCritical?.shipmentInstId || originalTimeDateCritical?.shipmentInstId,
      dateSelection: correctedTimeDateCritical?.tdcDateTypeCd,
      initialRangeDate: this.tdcCommonMapperService.isSelectedField(correctedTimeDateCritical?.tdcDateTypeCd, CorrectionsDateTimeTypesEnum.range, correctedTimeDateCritical?.tdcDate1),
      endRangeDate: this.tdcCommonMapperService.isSelectedField(correctedTimeDateCritical?.tdcDateTypeCd, CorrectionsDateTimeTypesEnum.range, correctedTimeDateCritical?.tdcDate2),
      initialOnDate: this.tdcCommonMapperService.isSelectedField(correctedTimeDateCritical?.tdcDateTypeCd, CorrectionsDateTimeTypesEnum.on, correctedTimeDateCritical?.tdcDate1),
      initialByDate: this.tdcCommonMapperService.isSelectedField(correctedTimeDateCritical?.tdcDateTypeCd, CorrectionsDateTimeTypesEnum.by, correctedTimeDateCritical?.tdcDate1),
      timeSelection: this.tdcCommonMapperService.getTimeSelectionValue(correctedTimeDateCritical?.tdcTimeTypeCd),
      initialRangeTime: this.tdcCommonMapperService.isSelectedField(correctedTimeDateCritical?.tdcTimeTypeCd, CorrectionsDateTimeTypesEnum.range, correctedTimeDateCritical?.tdcTime1),
      endRangeTime: this.tdcCommonMapperService.isSelectedField(correctedTimeDateCritical?.tdcTimeTypeCd, CorrectionsDateTimeTypesEnum.range, correctedTimeDateCritical?.tdcTime2),
      initialAfterTime: this.tdcCommonMapperService.isSelectedField(correctedTimeDateCritical?.tdcTimeTypeCd, CorrectionsDateTimeTypesEnum.after, correctedTimeDateCritical?.tdcTime1),
      initialBeforeTime: this.tdcCommonMapperService.isSelectedField(correctedTimeDateCritical?.tdcTimeTypeCd, CorrectionsDateTimeTypesEnum.before, correctedTimeDateCritical?.tdcTime1)
    };
  }

  private getReason(correctedResponse: GetCorrectedShipmentResp): CorrectionsReasonModel | undefined {
    const reasonCd = correctedResponse?.correctionRequest?.reasonCd;
    if (!reasonCd) return;

    let reasonsList: CorrectionsListMetadataReasonDescriptionModel[] = [];
    this.correctionsHandler.getListCorrectionsReasonDescriptions$.pipe(take(1)).subscribe(
      reasonDescriptions => reasonsList = reasonDescriptions || []
    );

    const reason = reasonsList.find(reason => reason.code === reasonCd);
    if (!reason) return;

    return {
      category: reason.reasonCategoryCode,
      description: reason.code
    };
  }

  private getAsWeight(correctedResponse: GetCorrectedShipmentResp, originalResponse: GetShipmentForPotentialCorrectionResp): CorrectionsAsWeightRespModel | undefined {
    const correctedAsWeightValue = correctedResponse?.correctedShipment?.ratedAsWeightLine;
    const originalAsWeightValue = originalResponse?.shipment?.asWeightLine;

    const correctedDeficitWeightValue = correctedResponse?.correctedShipment?.deficitWeightLine;

    // NOTE: When correctedAsWeightValue is null but correctedDeficitWeightValue has values means that the correction was applied to deficit weight
    if (!correctedAsWeightValue && correctedDeficitWeightValue) return;

    // NOTE: When correctedAsWeightValue and originalAsWeightValue are null mean that there not As weight correction or original value
    if (!correctedAsWeightValue && !originalAsWeightValue) return;

    return {
      amount: correctedAsWeightValue?.amount != null ? correctedAsWeightValue?.amount : originalAsWeightValue?.amount,
      description: correctedAsWeightValue?.description != null ? correctedAsWeightValue?.description : originalAsWeightValue?.description,
      quantity: correctedAsWeightValue?.quantity != null ? correctedAsWeightValue?.quantity : originalAsWeightValue?.quantity,
      rate: correctedAsWeightValue?.tariffsRate != null ? correctedAsWeightValue?.tariffsRate : originalAsWeightValue?.tariffsRate,
      type: correctedAsWeightValue?.lineTypeCd != null ? correctedAsWeightValue?.lineTypeCd : originalAsWeightValue?.lineTypeCd,
      sequenceNbr: correctedAsWeightValue?.sequenceNbr != null ? correctedAsWeightValue?.sequenceNbr : originalAsWeightValue?.sequenceNbr,
      minimumChargeInd: correctedAsWeightValue?.minimumChargeInd != null ? correctedAsWeightValue.minimumChargeInd : originalAsWeightValue.minimumChargeInd
    };
  }

  private getDeficitWeight(correctedResponse: GetCorrectedShipmentResp, originalResponse: GetShipmentForPotentialCorrectionResp): CorrectionsDeficitWeightRespModel | undefined {
    const correctedDeficitWeightValue = correctedResponse?.correctedShipment?.deficitWeightLine;
    const originalDeficitWeightValue = originalResponse?.shipment?.deficitWeightLine;

    const correctedAsWeightValue = correctedResponse?.correctedShipment?.ratedAsWeightLine;

    // NOTE: When correctedDeficitWeightValue is null but correctedAsWeightValue has values mean that the correction was applied to As weight
    if (!correctedDeficitWeightValue && correctedAsWeightValue) return;

    // NOTE: When correctedDeficitWeightValue and originalDeficitWeightValue are null mean that there not Deficit weight correction or original value
    if (!correctedDeficitWeightValue && !originalDeficitWeightValue) return;

    return {
      amount: correctedDeficitWeightValue?.amount != null ? correctedDeficitWeightValue.amount : originalDeficitWeightValue?.amount,
      description: correctedDeficitWeightValue?.description != null ? correctedDeficitWeightValue.description : originalDeficitWeightValue?.description,
      quantity: correctedDeficitWeightValue?.quantity != null ? correctedDeficitWeightValue.quantity : originalDeficitWeightValue?.quantity,
      rate: correctedDeficitWeightValue?.tariffsRate != null ? correctedDeficitWeightValue.tariffsRate : originalDeficitWeightValue?.tariffsRate,
      type: correctedDeficitWeightValue?.lineTypeCd != null ? correctedDeficitWeightValue.lineTypeCd : originalDeficitWeightValue?.lineTypeCd,
      sequenceNbr: correctedDeficitWeightValue?.sequenceNbr != null ? correctedDeficitWeightValue.sequenceNbr : originalDeficitWeightValue?.sequenceNbr,
      minimumChargeInd: correctedDeficitWeightValue?.minimumChargeInd != null ? correctedDeficitWeightValue.minimumChargeInd : originalDeficitWeightValue.minimumChargeInd
    };
  }

  getTypeWeight(correctedResponse: GetCorrectedShipmentResp, originalResponse: GetShipmentForPotentialCorrectionResp): CorrectionsWeightFormTypeEnum | undefined {
    const correctedAsWeightLine = correctedResponse?.correctedShipment?.ratedAsWeightLine?.lineTypeCd;
    const originalAsWeightLine = originalResponse?.shipment?.asWeightLine?.lineTypeCd;
    const correctedDeficitWeightLine = correctedResponse?.correctedShipment?.deficitWeightLine?.lineTypeCd;
    const originalDeficitWeightLine = originalResponse?.shipment?.deficitWeightLine?.lineTypeCd;
    if (correctedAsWeightLine || originalAsWeightLine) {
        return CorrectionsWeightFormTypeEnum.asWeight;
    } else if (correctedDeficitWeightLine || originalDeficitWeightLine) {
        return CorrectionsWeightFormTypeEnum.deficitWeight;
    }
    return undefined;
  }

  private getWeightLine(correctedResponse: GetCorrectedShipmentResp, originalResponse: GetShipmentForPotentialCorrectionResp): CorrectionsWeightLineRespModel {
    return {
      deleteInd: correctedResponse?.correctedShipment?.deleteWeightAdjustmentsInd,
      typeWeightLine: this.getTypeWeight(correctedResponse, originalResponse),
      asWeight: this.getAsWeight(correctedResponse, originalResponse),
      deficitWeight: this.getDeficitWeight(correctedResponse, originalResponse)
    };
  }

  // UTILS ************************************************************************************************************************************************
  private getDateTime(dateTime: Date, fieldName: string, method: string): number | undefined {
    try {
      return this.commonDateMapperService.getTimeStampNumberFromApi(dateTime) || undefined;
    } catch (error) {
      console.error(error, `fieldName: ${fieldName}`, `Method: ${method}`, `Service: ${this.serviceName}`);
      return;
    }
  }
}

import { Injectable } from '@angular/core';
import { RatingMinimumChargeEnum } from '@xpo-ltl/rating';

@Injectable({
  providedIn: 'root'
})
export class MinimumChargeService {

  constructor() { }

  getMinimumChargeInd(value: number | string | null): boolean {
    return value == RatingMinimumChargeEnum.value;
  }
}

import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UserPreferenceHandlerService } from '@app-store/user-preference/handlers/user-preference-handler.service';
import { UserHandler } from '@app-store/user/handler/user.handler';
import { AccountUrls } from '@shared/enums/common-data/account-urls.enum';
import { AppTitle } from '@shared/enums/common-data/app-title.enum';
import { ConfigManagerProperties } from '@shared/enums/common-data/config-manager-properties.enum';
import { UserPreferenceEnum } from '@shared/enums/common-data/user-preference.enum';
import { UserModel } from '@shared/models/shared/user.model';
import { CorrectionsRoutes } from '@shared/routes/corrections-routes.constant';
import { AppSwitcherDataService } from '@shared/services/data/app-switcher-data/app-switcher-data.service';
import { SubCollection } from '@shared/utils/rx/sub-collection';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import { XpoAppSwitcherApplication } from '@xpo-ltl/ngx-auth-ui';
import { XpoAuthenticationService } from '@xpo/ngx-auth';
import { invoke as _invoke, isEmpty as _isEmpty } from 'lodash';
import { interval, Observable } from 'rxjs';
import { filter, skipWhile, take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class AppComponent implements OnInit, OnDestroy {
  title = AppTitle.main;
  readonly accountUrls = AccountUrls;

  switcherApps$: Observable<XpoAppSwitcherApplication[]>;
  subs$ = new SubCollection();

  constructor(
    private appSwitcherDataService: AppSwitcherDataService,
    private ltlAuthService: XpoLtlAuthenticationService,
    private authService: XpoAuthenticationService,
    private configManagerService: ConfigManagerService,
    private userHandler: UserHandler,
    private userPreferenceHandlerService: UserPreferenceHandlerService
  ) { }

  ngOnInit(): void {
    this.getLoggedUser();
    this.setAppSwitcherApps();
    this.setRegion();
    this.getUserPreferences();
  }

  ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }

  getLoggedUser(): void {
    this.subs$.add = this.userHandler.getLoggedUser$
      .pipe(
        filter(user => !!user),
        take(1)
      )
      .subscribe(user => {
          if (user) {
            this.setDynatraceUserIdentity(user)
          }
        }
      );
  }

  setAppSwitcherApps(): void {
    this.switcherApps$ = this.appSwitcherDataService.getAppSwitcherApps();
  }

  setRegion(): void {
    const region = this.configManagerService.getSetting<string>(ConfigManagerProperties.region);
    this.ltlAuthService
      .initAuthSetup$(region)
      .pipe(take(1))
      .subscribe((_) => this.userHandler.getUser());
  }

  setDynatraceUserIdentity(user: UserModel): void {
    const windowName: any = 'dtrum';
    const setUser = (): void =>
      _invoke(
        window[windowName],
        'identifyUser',
        !_isEmpty(user.email) ? user.email : !_isEmpty(user.userId) ? user.userId : 'Correction_User'
      );

    if (((window[windowName] as any) || {}).identifyUser) {
      setUser();
    } else {
      let retryCount: number = 0;
      interval(1000)
        .pipe(
          tap(() => retryCount++),
          skipWhile(() => !((window[windowName] as any) || {}).identifyUser && retryCount <= 60),
          take(1)
        )
        .subscribe(() => {
          setUser();
        });
    }
  }

  getProfilePictureUrl(email: string): string {
    return `${this.accountUrls.switchApiUrl}${email}${this.accountUrls.pictureUrl}`;
  }

  signOut(): void {
    this.authService.signOut$().pipe(take(1)).subscribe();
  }

  getCorrectionsLink(): string {
    return CorrectionsRoutes.main;
  }

  getUserPreferences(): void {
    this.userPreferenceHandlerService.getUserPreference(UserPreferenceEnum.componentName);
  }
}

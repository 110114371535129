import { DateFormat } from '@shared/enums/common-data/date-format.enum';
import * as moment from 'moment';

export abstract class DateUtils {

  constructor() { }

  static momentToString(date: string | moment.MomentInput, format = DateFormat.defaultApiDateFormat): string {
    if (!date) return '';
    return typeof date === 'string' ? date : moment(date).format(format);
  };

  static getTimeFromMoment(date: number | moment.MomentInput): number | undefined {
    if (!date) return;
    return typeof date === 'number' ? date : moment(date).valueOf();
  };

  static getDateFromString(date: string, format = DateFormat.defaultApiDateFormat): moment.Moment {
    return moment(date, format);
  }

  static getCurrentDateString(format = DateFormat.defaultApiDateFormat): string {
    return this.momentToString(moment(), format);
  }

  static getCurrentDateTimeStamp(): number {
    return Date.now();
  }

  static getDateFromTimeStamp(timestamp: number, format = DateFormat.timestamp): string {
    return moment(timestamp).format(format);
  }

  static getDateWithFormat(date: string, format = DateFormat.defaultApiDateFormat): string {
    return moment(date).format(format).toString();
  }

  static dateComparator(date1: string, date2: string): number {
    if (!date1 && !date2) {
      return 0;
    }
    if (!date1) {
      return -1;
    }
    if (!date2) {
      return 1;
    }
    const date1Moment = moment(date1, DateFormat.timestamp);
    const date2Moment = moment(date2, DateFormat.timestamp);

    return date1Moment > date2Moment ? 1 : -1;
  }
}

import { Injectable } from '@angular/core';
import { MiscLineItem } from '@xpo-ltl/sdk-common';
import { CorrectionsAsWeightRespModel, CorrectionsDeficitWeightRespModel } from '@shared/models/corrections/corrections-detail.model';

@Injectable()
export class CorrectionsPreviewRatesCommonMetadataMapperService {

  getDeficitWeight(response: MiscLineItem | undefined): CorrectionsDeficitWeightRespModel {
    if (!response) return undefined as any;

    return {
      type: response.lineTypeCd,
      description: response.description,
      quantity: response.quantity,
      rate: response.tariffsRate,
      amount: response.amount,
      sequenceNbr: response.sequenceNbr,
      minimumChargeInd: response.minimumChargeInd
    };
  }

  getAsWeight(response: MiscLineItem | undefined): CorrectionsAsWeightRespModel {
    if (!response) return undefined as any;

    return {
      type: response.lineTypeCd,
      description: response.description,
      quantity: response.quantity,
      rate: response.tariffsRate,
      amount: response.amount,
      sequenceNbr: response.sequenceNbr,
      minimumChargeInd: response.minimumChargeInd
    };
  }
}

import { Injectable } from '@angular/core';
import { CorrectionAccessorial } from '@xpo-ltl-2.0/sdk-billcorrection';
import { AccessorialService } from '@xpo-ltl/sdk-common';

@Injectable({
  providedIn: 'root'
})
export class AccessorialsMapperService {

  constructor() { }

  getId(accessorial: CorrectionAccessorial | AccessorialService): string {
    return accessorial.sequenceNbr;
  }

  getIdForNewItem(id: number | string): string {
    return `NEW-${id}`;
  }
}
